
import React from "react";

interface TextAreaProps {
    id: string;
    label?: string;
    type: string;
    error?: string | undefined;
    register: any;
    rows?: number;
    cols?: number
}

const TextArea: React.FC<TextAreaProps> = ({ id, label, type, error, register, rows = 5, cols = 3 }) => {
    return (
        <React.Fragment>
            {label && <label className="label">{label}</label>}
            <textarea {...register(id)} type={type} className="form-control" rows={rows} cols={cols} style={{ height: "auto" }} />
            {
                error &&
                <p className="form-error" style={{ color: '#981e32' }}>{error}</p>
            }
        </React.Fragment>
    )
}

export default TextArea;