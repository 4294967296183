import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router";
import { useAppSelector } from "../../app/hooks";
import { useGetEventsQuery } from "../../services/events";
import EventListCardComponent from "./EventListCard.component";
import EventListFilterComponent from "./EventListFilter.component";
import EventListMenuHeaderComponent from "./EventListMenuHeader.component";

const EventList: React.FC = () => {

  const location = useLocation();
  const goToCalendar = _.get(location, "state.goToCalendar");
  const loggedUser = useAppSelector((state) => state.auth.user);
  const { listType } = useParams<{ listType: string }>();
  const [disableScroll, setDisableScroll] = useState(false);
  const [viewAs, setViewAs] = useState<string>(loggedUser?._id || "");
  const [currentDate, setCurrentDate] = useState<Date>(moment().startOf("day").toDate());
  const [previosScrollElement, setPreviousScrollElement] = useState<string | null>(null);
  const [params, setParams] = useState({
    // "sort[dateFrom]": 1,
    "select": "participants,dateFrom,dateTo,name,creator",
    "listType": listType,
    "limit": 30
  });
  const { data, refetch } = useGetEventsQuery(Object.assign({}, params, { "currentDay": moment(currentDate).format("x"), "viewAs": viewAs }));

  useEffect(() => {
    setCurrentDate(moment().startOf("day").toDate());
    if (listType !== "my-calendar") {
      setViewAs(loggedUser?._id || "");
    }
    setParams(Object.assign({}, params, {
      listType
    }));
  }, [listType]);

  useEffect(() => {
    setCurrentDate(moment().startOf("day").toDate());
    refetch();
  }, [viewAs]);

  useEffect(() => {
    if (goToCalendar) {
      setViewAs(goToCalendar);
    }
  }, [goToCalendar])

  const renderEventsGrouped = () => {
    if (data && data.documents instanceof Array) {
      if (_.isEmpty(data.documents)) {
        return <div className="text-muted text-center mt-5">- Brak wydarzeń -</div>
      }
      let documents = _.sortBy(data.documents, (doc) => parseInt(moment(doc.dateFrom).format("X")));
      let elements: React.ReactElement[] = [];
      //grupujemy po miesiacu
      let groupedByMonth = _.groupBy(documents, (event) => _.upperFirst(moment(event.dateFrom).format("MMMM YYYY")));
      let monthIndex = 0;

      _.each(groupedByMonth, (monthGroup, monthNumber) => {
        //elementy dla danego miesiaca
        let monthElements: React.ReactElement[] = [];
        let monthNumberSplit = monthNumber.split(" ");
        if (monthIndex > 0) {
          elements.push(<div className="month-separator pt-4 my-3" key={`separator${monthNumber}`}>
            <h2 className="month-name text-right  px-4 py-0">{monthNumberSplit[0]}<span className="year pl-2">{monthNumberSplit[1]}</span></h2>
          </div>)
        }

        let groupedByDay = _.groupBy(monthGroup, (event) => _.upperCase(moment(event.dateFrom).format("ddd D")));
        let dayIndex = 0;
        _.each(groupedByDay, (dayGroup, dayNumber) => {
          //elementy dla danego dnia
          let dayElements: React.ReactElement[] = [];
          let splitDayNumber = dayNumber.split(" ");
          let dayLabelId = moment(`${splitDayNumber[1]} ${monthNumber}`, "D MMMM YYYY").format("DD.MM.YYYY");
          if (dayLabelId === moment().format("DD.MM.YYYY")) {
            dayElements.push(<div className="current-time" style={{ top: "10%" }} key={`currentDay`}></div>);
          }
          //div event
          _.each(dayGroup, (event, eventIndex) => {
            if (eventIndex === 0) {
              //div day container czyli label z numerem dnia przy evencie po lewej
              dayElements.push(<div className="date-container col-2 text-center pl-0 pt-4" key={`${monthNumber}-${dayNumber}-label`}>
                <h5 className="day-name">{splitDayNumber[0]}</h5>
                <h3 className="day-number">{splitDayNumber[1]}</h3>
              </div>);
            }
            dayElements.push(<EventListCardComponent event={event} key={event._id} />)
          });
          //div day i zawartosc
          monthElements.push(<div className="day d-flex flex-wrap justify-content-end py-2 mx-4" key={`month${monthNumber}-day${dayNumber}`} id={dayLabelId}>{dayElements}</div>);
          dayIndex++;
        });
        monthIndex++;
        //div month i zawartosc
        elements.push(<div className="month d-flex flex-column" key={`month${monthNumber}`}>{monthElements}</div>)
      });
      return elements;
    }
    return null;
  }

  useEffect(() => {
    if (!disableScroll) {
      const element = document.getElementById(moment(currentDate).format("DD.MM.YYYY"));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      setDisableScroll(false);
    }
  }, [data]);

  const handleScroll = (e: any) => {
    var element = document.getElementById("event-list");
    if (element) {
      let offset = element.scrollHeight - element.scrollTop;
      let scrollTop = element.scrollTop;
      if (offset === element.clientHeight) {
        let elements = document.querySelectorAll(".event-list .day");
        if (elements.length > 0) {
          let lastElement = elements[elements.length - 1];
          if (lastElement.id !== previosScrollElement) {
            setDisableScroll(true);
            setCurrentDate(moment(lastElement.id, "DD.MM.YYYY").toDate());
            setPreviousScrollElement(lastElement.id);
          }
        }
      } else if (scrollTop === 0) {
        let firstElement = document.querySelector(".event-list .day:first-child");
        if (firstElement && firstElement.id !== previosScrollElement) {
          setDisableScroll(true);
          setCurrentDate(moment(firstElement.id, "DD.MM.YYYY").toDate());
          document.querySelector(".event-list .day:first-child .date-container")?.scrollIntoView({ behavior: 'auto' });
          setPreviousScrollElement(firstElement.id);
        }
      }
    }
  }

  useEffect(() => {
    let element = document.getElementById("event-list");
    if (element) {
      element.addEventListener("mousewheel", handleScroll, { passive: true });
      element.addEventListener("touchmove", handleScroll, { passive: true });
      element.addEventListener("reload", refetch);
    }

    return () => {
      let element = document.getElementById("event-list");
      if (element) {
        element.removeEventListener("mousewheel", handleScroll);
        element.removeEventListener("touchmove", handleScroll);
        element.removeEventListener("reload", refetch);
      }
    }
  }, [])

  return (<React.Fragment>
    <EventListMenuHeaderComponent viewAs={viewAs} onViewChange={setViewAs} />
    <EventListFilterComponent onDateChange={setCurrentDate} currentDate={currentDate} />
    <div className="event-list d-flex flex-column" id="event-list">
      {renderEventsGrouped()}
    </div>
  </React.Fragment>)
};

export default EventList;
