import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppSelector } from "../../app/hooks";
import store from "../../app/store";
import UserAutocompleteComponent from "../../components/UserAutocomplete.component";
import UserAvatarComponent from "../../components/UserAvatar.component";
import { setIsLoading } from "../../features/app/appSlice";
import {
    Event,
    useGetEventQuery,
    useInviteUsersToEventMutation, useReplyToEntryRequestMutation, useReplyToInvitationMutation,
    useRequestEntryMutation
} from "../../services/events";

const EventDetailsComponent: React.FC = () => {
    const loggedUser = useAppSelector((state) => state.auth.user);
    const loggedUserId = _.get(loggedUser, "_id", "");
    let loggedUserLawyerIds: string[] = [];
    _.each(_.get(loggedUser, "lawyers", []), (lawyer) => {
        loggedUserLawyerIds.push(lawyer._id);
    });
    const { id, listType } = useParams<{ listType: string, id: string }>();
    const [invited, setInvited] = useState<string[]>([]);
    const [joinAs, setJoinAs] = useState<string[]>([]);
    const { data, isLoading: isEventLoading, refetch } = useGetEventQuery(id);
    const event: Event = data?.document;

    const [inviteUsersToEvent, { isLoading: isInviteLoading, isSuccess: isInviteSuccess, isError: isInviteError }] = useInviteUsersToEventMutation();
    const [replyToInvitation, { isLoading: isInvitationReplyLoading, isSuccess: isInvitationReplySuccess, isError: isInvitationReplyError }] = useReplyToInvitationMutation();
    const [requestEntry, { isLoading: isRequestEntryLoading, isSuccess: isRequestEntrySuccess, isError: isRequestEntryError }] = useRequestEntryMutation();
    const [replyToEntryRequest, { isLoading: isReplyToEntryRequestLoading, isSuccess: isReplyToEntryRequestSuccess, isError: isReplyToEntryRequestError }] = useReplyToEntryRequestMutation();

    let isMyEvent = loggedUser?._id === _.get(event, "creator._id");
    let isUserEventAdmin = _.get(event, "isUserEventAdmin", false);
    let isUserAssistant = loggedUser?.type === "assistant";
    let loggedUserStatus = null;
    let rejectionReason = "";

    if (loggedUserStatus !== "invited" && loggedUserStatus !== "requestedEntry") {
        loggedUserLawyerIds.push(loggedUserId);
    }

    useEffect(() => {
        refetch();
        if (isInviteSuccess || isInvitationReplySuccess || isRequestEntrySuccess || isReplyToEntryRequestSuccess) {
            toast.success("Zapisano");
        }
    }, [isInviteSuccess, isInvitationReplySuccess, isRequestEntrySuccess, isReplyToEntryRequestSuccess]);

    useEffect(() => {
        refetch();
        if (isInviteError || isInvitationReplyError || isRequestEntryError || isReplyToEntryRequestError) {
            toast.error("Wystąpił błąd");
        }
    }, [isInviteError, isInvitationReplyError, isRequestEntryError, isReplyToEntryRequestError]);

    useEffect(() => {
        let isLoading = isInviteLoading || isInvitationReplyLoading || isRequestEntryLoading || isEventLoading || isReplyToEntryRequestLoading;
        store.dispatch(setIsLoading(isLoading));
    }, [isInviteLoading, isInvitationReplyLoading, isRequestEntryLoading, isEventLoading, isReplyToEntryRequestLoading]);

    if (!event) return null;

    _.each(event.participants, (participant) => {
        if (loggedUser?._id === _.get(participant, "user._id", null)) {
            if (participant.status === "rejected") {
                rejectionReason = participant.rejectReason || "";
            }
            loggedUserStatus = participant.status;
        }
    });

    let confirmedParticipants: React.ReactElement[] = [];
    let invitedParticipants: React.ReactElement[] = [];
    let rejectedParticipants: React.ReactElement[] = [];
    let requestedEntryParticipants: React.ReactElement[] = [];

    const handleRejectInvitation = () => {
        let input: any = document.getElementById("invitationRejectReasonInput");
        if (input && input.value && input.value.length > 0) {
            replyToInvitation({ event: id, status: "rejected", rejectReason: input.value });
        } else {
            toast.error("Podaj powód odmowy");
        }
    }

    const handleRejectEntryRequest = (participant: {
        user: any;
        status: string;
        rejectReason?: string | undefined;
    }, index: number) => {
        let input: any = document.getElementById(`rejectFormInput${index}`);
        if (input && input.value && input.value.length > 0) {
            replyToEntryRequest({ event: id, user: _.get(participant, "user._id", ""), status: "rejected", rejectReason: input.value });
        } else {
            toast.error("Podaj powód odmowy");
        }
    }

    event.participants.forEach((participant, index) => {
        switch (participant.status) {
            case "accepted": {
                confirmedParticipants.push(<li className="d-flex align-items-center justify-content-between flex-wrap" key={`participant${index}`}>
                    <div className="d-flex align-items-center">
                        <UserAvatarComponent user={participant.user} />{_.get(participant, "user.name", "Uczestnik")}
                    </div>
                </li>)
                break;
            }
            case "rejected": {
                rejectedParticipants.push(<li className="d-flex align-items-center justify-content-between flex-wrap" key={`participant${index}`}>
                    <div className="d-flex align-items-center">
                        <UserAvatarComponent user={participant.user} />{_.get(participant, "user.name", "Uczestnik")}
                    </div>
                    <div className="request-decision">
                        <a className="declined m-0" data-toggle="collapse" href={`#rejected${index}`} aria-expanded="false" aria-controls={`rejected${index}`}>
                            <div data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Zobacz powód"></div>
                        </a>
                    </div>
                    <div className="collapse w-100" id={`rejected${index}`}>
                        <textarea className="w-100 card card-body my-2 p-2" placeholder={participant.rejectReason} readOnly={true} ></textarea>
                        {/* <button className="btn btn-info w-100 my-2">Przywróć</button> */}
                    </div>
                </li>)
                break;
            }
            case "invited": {
                invitedParticipants.push(<li className="d-flex align-items-center justify-content-between flex-wrap" key={`participant${index}`}>
                    <div className="d-flex align-items-center">
                        <UserAvatarComponent user={participant.user} />{_.get(participant, "user.name", "Uczestnik")}
                    </div>
                </li>);
                break;
            }
            case "requestedEntry": {
                requestedEntryParticipants.push(<li className="d-flex align-items-center justify-content-between flex-wrap" key={`participant${index}`}>
                    <div className="d-flex align-items-center">
                        <UserAvatarComponent user={participant.user} />{_.get(participant, "user.name", "Uczestnik")}
                    </div>
                    <div className="request-decision">
                        <div className="accept" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Zaakceptuj zgłoszenie" onClick={() => replyToEntryRequest({ event: id, user: _.get(participant, "user._id", ""), status: "accepted" })}>
                        </div>
                        <a className="decline" data-toggle="collapse" href={`#rejectForm${index}`} aria-expanded="false" aria-controls={`rejectForm${index}`}>
                            <div data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Odmów zgłoszenie">
                            </div>
                        </a>
                    </div>
                    <div className="w-100 collapse" id={`rejectForm${index}`}>
                        <textarea className="w-100 card card-body my-2 p-2" placeholder="Podaj powód usunięcia uczestnika" id={`rejectFormInput${index}`}></textarea>
                        <button className="btn btn-danger w-100 my-2" onClick={() => handleRejectEntryRequest(participant, index)}>Odmów</button>
                    </div>
                </li>)
                break;
            }
            default:
        }
    });

    return (<React.Fragment>
        <div className="row content-heading pt-2">
            <div className="col-sm-1">
                <div className="date-container text-center mt-3">
                    <h5 className="day-name">{moment(event.dateFrom).format("ddd")}</h5>
                    <h3 className="day-number">{moment(event.dateFrom).format("D")}</h3>
                </div>
            </div>
            <div className="col-sm-11 d-flex justify-content-between">
                <h1 className="event-name align-self-end">{event.name}</h1>
                {isMyEvent && <Link to={`/events/${listType}/form/${id}`} type="button" className="btn btn-outline-success mr-5 mt-3 align-self-center btn font-weight-bold px-md-4 px-sm-2">
                    Edytuj
                </Link>}
            </div>
        </div>
        <div className="row content-row px-2">
            <div className="col-sm-12 col-md-8 d-flex flex-wrap align-content-start">
                <div className="field">
                    <label className="label">Czas</label>
                    <div className="time">
                        <span className="start-time">{moment(event.dateFrom).format("HH:mm")}</span> - <span className="end-time">{moment(event.dateTo).format("HH:mm")}</span>
                    </div>

                </div>
                {event.location && <div className="field">
                    <label className="label">Lokalizacja</label>
                    <div className="text place">
                        {event.location}
                    </div>

                </div>}
                <div className="field">
                    <label className="label">Opis</label>
                    <div className="text description">
                        {event.description}
                    </div>
                </div>
            </div>
            <div className="users-panel col-sm-12 col-md-4 px-md-3 d-flex flex-wrap align-content-start">

                <div className="field">
                    <label className="label">Organizator</label>
                    <div className="text description">
                        <ul className="users-list hosts">
                            <li className="d-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-center">
                                    <UserAvatarComponent user={event.creator} />{isMyEvent ? "Ja" : _.get(event, "creator.name", "Organizator")}
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                {confirmedParticipants.length > 0 && <div className="field">
                    <label className="label">Uczestnicy</label>
                    <div className="text description">
                        <ul className="users-list guests">
                            {confirmedParticipants}
                        </ul>
                    </div>
                </div>}
                {isUserEventAdmin && requestedEntryParticipants.length > 0 && <div className="field">
                    <label className="label">Oczekujący</label>
                    <div className="text description">
                        <ul className="users-list guests">
                            {requestedEntryParticipants}
                        </ul>
                    </div>
                </div>}
                {isUserEventAdmin && invitedParticipants.length > 0 && <div className="field">
                    <label className="label">Zaproszeni</label>
                    <div className="text description">
                        <ul className="users-list guests">
                            {invitedParticipants}
                        </ul>
                    </div>
                </div>}
                {isUserEventAdmin && rejectedParticipants.length > 0 && <div className="field">
                    <label className="label">Odrzuceni</label>
                    <div className="text description">
                        <ul className="users-list denied">
                            {rejectedParticipants}
                        </ul>
                    </div>
                </div>}
            </div>
        </div>
        {/* Osobny case dla odrzuconego bo sie style rozwalały */}
        {loggedUserStatus === "rejected" && (<div className="row btn-panel d-flex p-4 pl-md-5 justify-content-end">
            <textarea className="col-sm-12 col-md-4 card card-body my-2 ml-md-5 text-decoration-none" placeholder={rejectionReason} disabled></textarea>
            <div className="col-sm-12 d-flex pt-3 px-0 justify-content-end align-items-center flex-wrap">
                <div className="text-danger border-0 mx-3">Zgłoszenie odrzucone</div>
                <button className="btn btn-primary" disabled>Dołącz</button>
            </div>
        </div>)}

        <div className="row btn-panel">
            <div className={`col-sm-12 d-flex pt-3 px-0 flex-wrap ${(loggedUserStatus === "invited" && isUserAssistant) ? "justify-content-between align-items-start" : "justify-content-end align-items-end"}`}>

                {/* Odrzucenie lub akceptowanie zaproszenia - jestem zaproszony na event*/}
                {loggedUserStatus === "invited" && <div className="col-sm-6 d-flex pt-3 px-md-4 mt-3 d-flex flex-wrap align-items-center">
                    <button className="btn btn-success" onClick={() => replyToInvitation({ event: id, status: "accepted" })}>Akceptuj</button>
                    <a className="btn btn-outline-danger border-0" href="#invitationRejectReason" data-toggle="collapse" aria-expanded="false" aria-controls="invitationRejectReason">
                        <div data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Odrzuć zgłoszenie">Odrzuć</div>
                    </a>

                    <div className="collapse w-100" id="invitationRejectReason">
                        <textarea className="w-100 card card-body my-2 p-2 text-decoration-none" placeholder="Podaj powód odmowy uczestnictwa" id="invitationRejectReasonInput"></textarea>
                        <button className="btn btn-danger w-100 my-2 text-decoration-none" onClick={() => handleRejectInvitation()}>Odrzuć</button>
                    </div>
                </div>}

                {/* Wysłałem prośbę o dołączenie */}
                {loggedUserStatus === "requestedEntry" && <div className="text-info border-0 mx-3">Zgłoszenie wysłane</div>}

                {/* Disabled button dołącz - mam status odrzucono lub oczekuje */}
                {(loggedUserStatus === "requestedEntry") && <button className="btn btn-primary" disabled={true}>Dołącz</button>}

                {/* Button dołącz  - nie jestem twórcą eventu i nie jestem juz uczestnikiem ani asystentem*/}
                {!isMyEvent && !loggedUserStatus && !isUserAssistant && <button className="btn btn-primary" onClick={() => requestEntry({ event: id, users: [loggedUserId] })}>Dołącz</button>}

                {/* Button dołącz jako asystent - nie moge być twórcą eventu*/}
                {!isMyEvent && isUserAssistant && <div className="col-sm-4 field d-flex flex-wrap justify-content-between p-0 mr-1 px-3">
                    <UserAutocompleteComponent multi={true} label="Dołącz jako" id="joinAs" onOptionSelect={(options) => setJoinAs(options)} initialValues={[]} simpleValue={true} customParams={{ "_id[in]": loggedUserLawyerIds.join(",") }} />
                    {joinAs.length > 0 && <button className="btn btn-info mt-3" onClick={() => requestEntry({ event: id, users: joinAs })}>Dołącz</button>}
                </div>}

                {/* Zapraszanie uczestników jako zarządzający eventem */}
                {isUserEventAdmin && <div className="col-sm-4 field d-flex flex-wrap justify-content-between p-0 mr-1 px-3">
                    <UserAutocompleteComponent multi={true} label="Zaproszenia" id="invite" onOptionSelect={(options) => setInvited(options)} initialValues={[]} simpleValue={true} customParams={{ "_id[nin]": loggedUserId }} />
                    {invited.length > 0 && <button className="btn btn-primary mt-3" onClick={() => inviteUsersToEvent({ event: id, users: invited })}>Zaproś</button>}
                </div>}

            </div>
        </div>
    </React.Fragment >);
}

export default EventDetailsComponent;