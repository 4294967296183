
import React, { useEffect } from "react";
import MiddleMenuHeaderComponent from "../../components/MiddleMenuHeader.component";
import phoneIcon from "../../assets/images/phone-btn.svg";
import mailIcon from "../../assets/images/mail-btn.svg";
import SwitchComponent from "../../components/Switch.component";
import { useAppSelector } from "../../app/hooks";
import { useUpdateUserMutation } from "../../services/users";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import _ from "lodash";
import { handleApiErrors, handleMenusCollapse } from "../../app/utils";
import { toast } from "react-toastify";
import { useAppDispatch } from "../../app/hooks";
import { setIsLoading } from "../../features/app/appSlice";

const schema = yup.object({
    weeklySummary: yup.boolean(),
    dailySummary: yup.boolean()
})

type SettingsFormValues = {
    weeklySummary: boolean,
    dailySummary: boolean
}

const SettingsComponent: React.FC = () => {
    const dispatch = useAppDispatch();
    const loggedUser = useAppSelector((state) => state.auth.user);
    const [updateUser, { isLoading }] = useUpdateUserMutation();

    const {
        register,
        handleSubmit,
        setValue,
        setError,
        getValues,
        formState: { errors, isSubmitting },
    } = useForm<SettingsFormValues>({
        defaultValues: {
            weeklySummary: _.get(loggedUser, "notifications.weeklySummary", true),
            dailySummary: _.get(loggedUser, "notifications.dailySummary", true)
        },
        resolver: yupResolver(schema)
    });

    useEffect(() => {
        dispatch(setIsLoading(isLoading));
    }, [isLoading])

    const handleSubmitSettings = async (data: SettingsFormValues) => {
        try {
            let sendData = {
                _id: loggedUser!._id,
                notifications: {
                    weeklySummary: data.weeklySummary,
                    dailySummary: data.dailySummary
                }
            }
            const response = await updateUser(sendData as any).unwrap();
            if (response?.success) {
                toast.success('Zaktualizowano ustawienia');
            } else {
                toast.error('Wystąpił błąd');
            }
        } catch (err) {
            toast.error('Wystąpił błąd');
            handleApiErrors(setError, err);
        }
    }

    const handleClickSubmit = () => {
        if (!isSubmitting) {
            handleSubmit(handleSubmitSettings)();
        }
    }

    return (
        <React.Fragment>
            <MiddleMenuHeaderComponent
                headerText="Ustawienia"
            />
            <form className="settings-sidebar d-flex flex-column p-4" id="settings-form" onChange={handleClickSubmit}>
                <div className="row pb-0 d-flex ">
                    <div className="col-8">
                        <div className="text description">
                            <div className="field pt-0">
                                <label className="label">Powiadomienia</label>
                                <div className="text description">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-2 pl-3 notify-icon"><img src={phoneIcon} data-toggle="tooltip" data-placement="bottom" title="Powiadomienia na telefon" /> </div>
                    <div className="col-2 pl-3 notify-icon"><img src={mailIcon} data-toggle="tooltip" data-placement="bottom" title="Powiadomienia e-mail" /></div>
                </div>
                <div className="row pb-3">
                    <div className="col-8">
                        Podsumowanie tygodniowe
                    </div>
                    <div className="col-2 text-center">
                        <SwitchComponent id="weeklySummaryDisabled" error={undefined} register={register} disabled={true} customClass="custom-control custom-switch custom-switch-md" />
                    </div>
                    <div className="col-2 text-center">
                        <SwitchComponent id="weeklySummary" error={undefined} register={register} customClass="custom-control custom-switch custom-switch-md" />
                    </div>
                </div>
                <div className="row pb-3">
                    <div className="col-8">
                        Podsumowanie dzienne
                    </div>
                    <div className="col-2 text-center">
                        <SwitchComponent id="dailySummary" error={undefined} register={register} customClass="custom-control custom-switch custom-switch-md" />
                    </div>
                    <div className="col-2 text-center">
                        <SwitchComponent id="dailySummaryDisabled" error={undefined} register={register} disabled={true} customClass="custom-control custom-switch custom-switch-md" />
                    </div>
                </div>
                <div className="row p-3 d-flex flex-wrap">
                    <button id="profilesettings" type="button" className="btn btn-secondary hideonmobile" onClick={() => handleMenusCollapse(false, true)}>Ustawienia profilu</button>
                </div>
            </form>


        </React.Fragment >

    )
}

export default SettingsComponent;