import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { useHistory } from 'react-router-dom';
import { useEffect } from "react";
import { useAppDispatch } from "../../app/hooks";
import { useLoginMutation } from "../../services/auth";
import { setCredentials } from "../../features/auth/authSlice";
import ArrowLogin from "../../assets/images/arrow-login.svg";
import LogoDentons from "../../assets/images/logo-dentons.svg";
import TextInputComponent from "../../components/TextInput.component";

import schema from './loginForm.schema';

type LoginFormValues = {
    email: string;
    password: string;
};

const LoginForm: React.FC = () => {
    const history = useHistory();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<LoginFormValues>({
        resolver: yupResolver(schema),
    });
    const dispatch = useAppDispatch();
    const [login] = useLoginMutation();

    const handleSubmitLoginForm = async (data: LoginFormValues) => {
        try {
            const user = await login({
                email: data.email,
                password: data.password,
            }).unwrap();

            dispatch(setCredentials(user));
            history.replace('/events/public');
        } catch (err) {
            toast.error("Błąd autoryzacji");
        }
    };

    useEffect(() => {
        document.getElementById("content")?.classList.remove('active');
        document.getElementById("mobile-nav")?.classList.add('active');
    }, [])

    const handleResetEmail = (e: any) => {
        e.preventDefault();
        history.push(`/resetPassword`);
    }

    return (
        <div id="login" className="col-sm-12 px-3 py-0 ">
            <div className="dentons-logo-mobile">
                <img src={LogoDentons} />
            </div>

            <div className="row px-2 py-0 d-flex justify-content-between">
                <div className="col-md-6 col-sm-12 d-flex align-items-center mb-md-5 pb-md-5 ">
                    <h1 className="welcome-title px-4 text-white dentons-semi mb-md-5 pb-md-5 pt-0">
                        <span className="dentons-bold">Twoje spotkania</span>
                        <br />w jednym miejscu
                    </h1>
                </div>
                <div className="col-md-4 col-sm-12 d-flex align-items-start align-items-md-end justify-content-center">
                    <form
                        onSubmit={handleSubmit(handleSubmitLoginForm)}
                        className="pb-5 col-12 p-0 d-flex flex-wrap"
                    >
                        <div className="col-sm-12 field">
                            <div className="col-md-12">
                                <TextInputComponent
                                    label="Adres e-mail"
                                    id="email"
                                    type="text"
                                    error={errors.email?.message}
                                    register={register}
                                />
                            </div>
                            <div className="col-md-12">
                                <TextInputComponent
                                    label="Hasło"
                                    id="password"
                                    type="password"
                                    error={errors.password?.message}
                                    register={register}
                                />
                            </div>
                        </div>

                        <div className="col-12 mt-4 d-flex justify-content-end">
                            <button className="btn btn-outline-secondary mr-2 border-0" type="button" onClick={(e) => handleResetEmail(e)}>
                                Przypomnij hasło
                            </button>
                            <button className="btn btn-primary px-5" type="submit">
                                <img src={ArrowLogin} />
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default LoginForm;
