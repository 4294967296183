import React from "react";
import { baseUrl } from "../../app/api";
import { useParams } from "react-router";
import addIcon from "../../assets/images/add.svg";
import shareIcon from "../../assets/images/share-btn.svg";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import UserAvatarComponent from "../../components/UserAvatar.component";
import _ from "lodash";
import { toast } from "react-toastify";

interface EventListMenuHeaderComponentProps {
    onViewChange: (id: string) => void,
    viewAs: string
}

const EventListMenuHeaderComponent: React.FC<EventListMenuHeaderComponentProps> = ({ onViewChange, viewAs }) => {

    const { listType } = useParams<{ listType: string }>();
    const loggedUser = useAppSelector((state) => state.auth.user);
    if(!loggedUser) return null;
    
    const renderAvailibleCalendars = () => {
        if (loggedUser) {
            if (_.get(loggedUser, "lawyers.length", 0) > 0) {
                return (<React.Fragment>
                    <a className="dropdown-item" onClick={() => onViewChange(loggedUser._id)}>
                        <UserAvatarComponent user={loggedUser} /><strong>Ja</strong>
                    </a>
                    <div className="dropdown-divider"></div>
                    {loggedUser.lawyers.map((user, index) => {
                        return (<a className="dropdown-item" key={`calendar${index}`} onClick={() => onViewChange(user._id)}>
                            <UserAvatarComponent user={user} />{user._id === loggedUser._id ? "Ja" : user.name}
                        </a>);
                    })}
                </React.Fragment>)
            } else {
                return (<a className="dropdown-item">
                    <UserAvatarComponent user={loggedUser} /><strong>Ja</strong>
                </a>);
            }
        }
        return null;
    }

    const copyCalendarLink = async () => {
        let textToCopy = `${baseUrl}calendar/${viewAs}`;
        if (navigator.clipboard && window.isSecureContext) {
            await navigator.clipboard.writeText(textToCopy);
            toast.success("Skopiowano link do kalendarza");
        } else {
            let textArea = document.createElement("textarea");
            textArea.value = textToCopy;
            textArea.style.position = "fixed";
            textArea.style.left = "-999999px";
            textArea.style.top = "-999999px";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            return new Promise((res, rej): void => {
                //Wiem ze deprecated ale to na wszelki wypadek innego sposobu nie ma
                document.execCommand('copy');
                textArea.remove();
                toast.success("Skopiowano link do kalendarza");
            });
        }
    }

    const getHeaderText = () => {
        switch (listType) {
            case "public":
            default: {
                return <h1>Wydarzenia</h1>;
            }
            case "my-calendar": {
                let availibleUsers = _.concat(_.get(loggedUser, "lawyers", []), [loggedUser]);
                let activeUser = _.find(availibleUsers, (u) => _.get(u, "_id") === viewAs);
                return (<div className="btn-group show">
                    <button className="btn btn-transparent dropdown-toggle pr-4 pl-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                        <h1>{loggedUser?._id === viewAs ? "Mój kalendarz" : _.get(activeUser, "name", "Mój kalendarz")}</h1>
                    </button>
                    <div className="dropdown-menu users-list" x-placement="bottom-start" style={{ position: "absolute", transform: "translate3d(0px, 62px, 0px)", top: "0px", left: "0px", willChange: "transform" }}>
                        {renderAvailibleCalendars()}
                    </div>
                </div>);
            }
            case "reports": {
                return <h1>Zgłoszenia</h1>;
            }
        }
    }

    const getHeaderButtons = () => {
        switch (listType) {
            case "public":
            default: {
                return (<Link to={`/events/${listType}/form`}>
                    <button type="button" className="btn" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Dodaj wydarzenie" onClick={() => document.getElementById("content")?.classList.remove('active')}>
                        <img src={addIcon} />
                    </button>
                </Link>);
            }
            case "my-calendar": {
                return (<div>
                    <Link to={`/events/${listType}/form`}>
                        <button type="button" className="btn px-1" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Dodaj wydarzenie" onClick={() => document.getElementById("content")?.classList.remove('active')}>
                            <img src={addIcon} />
                        </button>
                    </Link>
                    {viewAs === loggedUser?._id && <button type="button" className="btn px-1" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Udostępnij kalendarz" onClick={() => copyCalendarLink()}>
                        <img src={shareIcon} />
                    </button>}
                </div>);
            }
            case "reports": {
                return null;
            }
        }
    }

    return (
        <React.Fragment>
            <div className="header p-4 d-flex justify-content-between align-items-center">
                {getHeaderText()}
                {getHeaderButtons()}
            </div>
        </React.Fragment>
    )
}

export default EventListMenuHeaderComponent;