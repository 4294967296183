
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import RemoveItemIcon from '../../assets/images/remove-item.svg'

import { useCreateUserMutation, useGetUserQuery, useUpdateUserMutation } from "../../services/users";
import { handleApiErrors } from "../../app/utils";
import TextInputComponent from "../../components/TextInput.component";
import Switch from '../../components/Switch.component';
import SimpleButtonComponent from '../../components/SimpleButton.component';
import UserAutocompleteComponent from '../../components/UserAutocomplete.component';
import UserAvatarComponent from '../../components/UserAvatar.component';
import { setIsLoading } from "../../features/app/appSlice";
import { useAppDispatch } from "../../app/hooks";

import ImageCropper from '../../components/ImageCropper.component';

type UserFormValues = {
    _id?: string,
    name: string,
    companyName: string,
    position: string,
    email: string,
    type: boolean | string,
    active: boolean,
    lawyers: any,
    phone: string,
    description: string,
    password: string,
    avatar: any
}

interface UserDetailsParams {
    id: string;
}

const schema = yup.object({
    name: yup.string().required('Pole jest wymagane'),
    companyName: yup.string(),
    position: yup.string(),
    email: yup.string().email("Niepoprawny format e-mail").required('Pole jest wymagane'),
    type: yup.string(),
    active: yup.bool(),
    phone: yup.string(),
    description: yup.string(),
    password: yup.string(),
})

const UserFormComponent: React.FC = () => {
    const history = useHistory();
    const params: UserDetailsParams = useParams();
    const { data: user } = useGetUserQuery(params.id);
    const [createUser, { isLoading: createLoading }] = useCreateUserMutation();
    const [updateUser, { isLoading: updateLoading }] = useUpdateUserMutation();
    const dispatch = useAppDispatch();

    dispatch(setIsLoading(createLoading || updateLoading));

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        watch,
        reset,
        formState: { errors },
    } = useForm<UserFormValues>({
        defaultValues: { active: true, avatar: null, type: true },
        resolver: yupResolver(schema)
    });

    const assignUserType = (type: boolean) => {
        if (type) return 'assistant'
        return 'lawyer';
    }

    const handleSubmitUserForm = async (data: UserFormValues) => {
        data.type = assignUserType(!!data.type);
        try {
            if (params && params.id) {
                data._id = user?.document?._id;
                const response = await updateUser(data).unwrap();
                if (response?.success) {
                    toast.success('Zaktualizowano użytkownika')
                } else {
                    toast.error('Wystąpił błąd');
                }

            } else {
                const response = await createUser(data).unwrap();
                if (response?.success) {
                    toast.success('Stworzono użytkownika');
                    if (response?.document?._id) {
                        history.push(`/users/${response.document._id}`);
                    }
                } else {
                    toast.error('Wystąpił błąd');
                }
            }
        } catch (err: any) {
            toast.error('Wystąpił błąd');
            handleApiErrors(setError, err);
        }
    }

    useEffect(() => {
        if (!params.id) {
            reset();
        } else {
            reset();
            if (user && user.document) {
                for (const [key, value] of Object.entries(user.document)) {
                    setValue(key as any, value)
                }
            }
        }
    }, [params, user]);

    const handleSelectAvatar = (image: string) => {
        setValue('avatar', image)
    }

    const handleRemoveAvatar = () => {
        setValue('avatar', null)
    }

    return (
        <React.Fragment>
            <div className="row content-heading px-2 py-0">
                <div className="col-sm-11 d-flex align-items-end justify-content-center justify-content-md-start">
                    <h1 className="page-title py-4 px-0">{user ? "Edycja użytkownika" : "Nowy użytkownik"}</h1>
                </div>
            </div>

            <form className="g-3 needs-validation col-12 p-0 d-flex flex-wrap" >
                <div className="row content-row px-2">
                    <div className="col-sm-12 p-0 pr-md-2 pb-4 d-flex flex-wrap align-content-start ">
                        <div className="field d-flex flex-wrap">

                            <div className="col-md-6">
                                <TextInputComponent
                                    label="Imię i nazwisko"
                                    id="name"
                                    type="text"
                                    error={errors.name?.message}
                                    register={register}
                                />
                            </div>
                            <div className="col-md-6 d-flex flex-wrap">
                                <label className="label col-sm-12 p-0">Zdjęcie profilowe</label>
                                <div className="pb-2">
                                    <UserAvatarComponent
                                        user={{
                                            name: watch('name'),
                                            avatar: watch('avatar')
                                        }}
                                    />
                                    {watch('avatar') && <img className="remove-item mx-3" src={RemoveItemIcon} onClick={handleRemoveAvatar} />}
                                </div>
                                <ImageCropper
                                    handleSelectAvatar={handleSelectAvatar}
                                />
                            </div>
                            <div className="col-md-12 p-0 d-flex">
                                <div className="col-md-6 ">
                                    <TextInputComponent
                                        label="Nazwa firmy"
                                        id="companyName"
                                        type="text"
                                        error={errors.companyName?.message}
                                        register={register}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <TextInputComponent
                                        label="Stanowisko"
                                        id="position"
                                        type="text"
                                        error={errors.position?.message}
                                        register={register}
                                    />
                                </div>
                            </div>
                            <div className="col-md-12 p-0 d-flex flex-wrap">
                                <div className="col-md-12">
                                    <Switch
                                        id="type"
                                        label="Stanowisko asystenckie"
                                        error={errors.type?.message}
                                        register={register}
                                        disabled={(params && params.id) ? true : false}
                                    />
                                </div>
                                {
                                    user &&
                                    <div className="col-md-12">
                                        <Switch
                                            id="active"
                                            label="Konto aktywne"
                                            error={errors.active?.message}
                                            register={register}
                                        />
                                    </div>
                                }
                                <div className="col-md-6 ">
                                    <TextInputComponent
                                        label="Adres e-mail"
                                        id="email"
                                        type="text"
                                        error={errors.email?.message}
                                        register={register}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <TextInputComponent
                                        label="Numer telefonu"
                                        id="phone"
                                        type="text"
                                        error={errors.phone?.message}
                                        register={register}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <TextInputComponent
                                        label="Opis"
                                        id="description"
                                        type="text"
                                        error={errors.description?.message}
                                        register={register}
                                    />
                                </div>
                                <div className="col-md-6">
                                    {watch('type') &&
                                        <UserAutocompleteComponent
                                            multi={true}
                                            label="Przypisz prawnika"
                                            id="invite"
                                            onOptionSelect={(option) => setValue('lawyers', option)}
                                            initialValues={user && user.document ? user.document.lawyers : []}
                                        />
                                    }

                                </div>
                                {!user && <div className="col-md-6">
                                    <TextInputComponent
                                        label="Hasło"
                                        id="password"
                                        type="text"
                                        error={errors.password?.message}
                                        register={register}
                                    />
                                </div>}
                            </div>

                        </div>
                    </div>
                </div>

                <div className="col-12 btn-panel mt-4">
                    <SimpleButtonComponent
                        title="Zapisz"
                        type="primary"
                        onClick={handleSubmit(handleSubmitUserForm)}
                    />
                </div>
            </form>

        </React.Fragment>

    )
}

export default UserFormComponent;