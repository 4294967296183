import * as React from "react";
import { useParams } from "react-router";
import { useGetUserQuery } from "../../services/users";
import { User } from '../../services/users';

import IconButtonComponent from '../../components/IconButton.component';
import UserListRowComponent from './UserListRow.component';
import { handleEmailLawyer, handlePhoneLawyer } from '../../app/utils'

interface UserDetailsParams {
    id: string;
}

const UserDetailsComponent: React.FC = () => {
    const params: UserDetailsParams = useParams();

    const { data: user } = useGetUserQuery(params.id);

    const userInitials = user?.document?.name.split(" ").map((word: any) => word[0].toUpperCase()).join("");

    const renderTeamMembers = () => {
        if (user && user.document && user.document.lawyers && user.document.lawyers.length) {
            return (
                <div className="col-sm-12 px-4 d-flex align-items-end justify-content-center justify-content-md-start">
                    <div className="field col-sm-6 pt-0">
                        <label className="label mt-0">Zespół</label>
                        <div className="text description">
                            <ul className="users-list">
                                {user.document.lawyers.map(((lawyer: User) => {
                                    return < UserListRowComponent
                                        key={lawyer._id}
                                        name={lawyer.name}
                                        avatar={lawyer.avatar}
                                        firstButtonAction={() => handleEmailLawyer(lawyer.email)}
                                        firstButtonImage="email"
                                        secondButtonAction={() => handlePhoneLawyer(lawyer.phone)}
                                        secondButtonImage="phone"
                                    />
                                }))}
                            </ul>

                        </div>
                    </div>
                </div>
            )
        }
    }

    return (
        <React.Fragment>
            <div className="profile row">
                <div className="col-sm-12 px-2 profile-heading d-flex flex-wrap">
                    <div className="col-sm-12 col-md-2 d-flex align-items-stretch">
                        {
                            (user && user.document && user.document.avatar) ?
                                <img src={user?.document?.avatar} className="profile-pic" />
                                : <div className="profile-pic bigAvatar bigInitials">
                                    {userInitials}
                                </div>
                        }

                    </div>
                    <div className="col-sm-12 col-md-10 pl-md-5 d-flex align-items-start justify-content-center flex-column">
                        <h1 className="dentons-semi">{user?.document?.name}</h1>
                        <h3>{user?.document?.companyName}</h3>
                        <h4>{user?.document?.position}</h4>
                    </div>
                </div>
                <div className="col-sm-12 px-4 profile-desc justify-content-center d-flex flex-wrap">
                    {user?.document?.email &&
                        <IconButtonComponent
                            text={user?.document?.email}
                            icon="email"
                            onClick={() => handleEmailLawyer(user?.document?.email)}
                        />
                    }
                    {user?.document?.phone &&
                        <IconButtonComponent
                            text={user?.document?.phone}
                            icon="phone"
                            onClick={() => handlePhoneLawyer(user?.document?.phone)}
                        />
                    }

               
                    <div className="w-100 my-2">{user?.document?.description} </div>
                </div>
                {renderTeamMembers()}
            </div>

        </React.Fragment>
    );
};

export default UserDetailsComponent;
