
import React from "react";
import _ from "lodash";

interface UserAvatarComponentProps {
    user: {
        avatar?: string,
        name: string
    }
}

const UserAvatarComponent: React.FC<UserAvatarComponentProps> = ({ user }) => {
    if (!_.get(user, "name")) {
        return <div className="avatar initials">??</div>
    }
    let userInitials = user.name.split(" ").filter((word) => !_.isEmpty(word)).map((word, index) => {
        return index < 2 ? word[0].toUpperCase() : "";
    }).join("");
    if (user.avatar) {
        return <img className="avatar image"
            src={user.avatar} />
    } else {
        return <div className="avatar initials">{userInitials}</div>
    }
}

export default UserAvatarComponent;