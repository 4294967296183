import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type {} from "../../services/users";
import type { RootState } from "../../app/store";

const intitialState: any = {
  user: null,
};

const slice = createSlice({
  name: "users",
  initialState: intitialState,
  reducers: {
    setUser: (state, { payload: { document } }: PayloadAction<any>) => {
      state.user = document;
    },
  },
});

export const { setUser } = slice.actions;

export const selectUser = (state: RootState) => state.users.user;

export default slice.reducer;
