import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAppDispatch } from "../app/hooks";
import KalendarzPubliczny from '../assets/images/kalendarz-publiczny-active.svg';
import MojKalendarz from '../assets/images/moj-kalendarz-active.svg';
import SidebarClose from '../assets/images/sidebar-close.svg';
import { handleMenusCollapse } from "../app/utils";
import { setCredentials } from "../features/auth/authSlice";
import { Keyboard } from '@capacitor/keyboard';
import { Capacitor } from "@capacitor/core";

const LeftMenuComponent: React.FC = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const [bottomBarVisible, setBottomBarVisible] = useState(true);

    const handleLogout = () => {
        dispatch(setCredentials({ token: null, user: null }));
        localStorage.removeItem('token');
        history.replace('/login');
        setShowMobileMenu(false);
    }

    if (Capacitor.getPlatform() !== "web") {
        Keyboard.addListener('keyboardWillShow', info => {
            setBottomBarVisible(false);
        });

        Keyboard.addListener('keyboardWillHide', () => {
            setBottomBarVisible(true);
        });
    }


    return (
        <React.Fragment>
            <nav className="menu">
                <div className="custom-menu">
                    <button
                        type="button"
                        id="sidebarCollapse"
                        className="btn btn-primary"
                    >
                        <img src={SidebarClose} />
                        <span className="sr-only">Zwiń</span>
                    </button>
                </div>
                <ul>
                    <Link to="/events/public" onClick={() => handleMenusCollapse(true, false)}><li
                        className="menu-item active public"
                        data-toggle="tooltip"
                        data-placement="right"
                        title="Wydarzenia publiczne"
                    ></li></Link>
                    <Link to="/users" onClick={() => handleMenusCollapse(true, false)}>
                        <li
                            className="menu-item partners"
                            data-toggle="tooltip"
                            data-placement="right"
                            title="Lista użytkowników"
                        ></li>
                    </Link>
                    <Link to="/events/my-calendar" onClick={() => handleMenusCollapse(true, false)}><li
                        className="menu-item my-calendar"
                        data-toggle="tooltip"
                        data-placement="right"
                        title="Mój kalendarz"
                    ></li></Link>
                    <Link to="/events/reports" onClick={() => handleMenusCollapse(true, false)}><li
                        className="menu-item requests"
                        data-toggle="tooltip"
                        data-placement="right"
                        title="Zgłoszenia"
                    ></li></Link>
                    <Link to="/myprofile" onClick={() => handleMenusCollapse(true, true)}>
                        <li
                            className="menu-item settings"
                            data-toggle="tooltip"
                            data-placement="right"
                            title="Ustawienia"
                        ></li>
                    </Link>
                    <li
                        onClick={() => handleLogout()}
                        className="menu-item logout"
                        data-toggle="tooltip"
                        data-placement="right"
                        title="Wyloguj"
                    ></li>
                </ul>
            </nav>

            {bottomBarVisible && <nav id="mobile-nav">
                <div id="menu-mobile" className={`d-flex flex-column justify-content-around ${showMobileMenu ? '' : 'active'}`}>
                    <h3 className="text-center text-white font-weight-bold">MENU</h3>
                    <ul className="d-flex flex-column">
                        <Link to="/events/public"><li className="menu-item public" onClick={() => setShowMobileMenu(!showMobileMenu)}>Wydarzenia</li></Link>
                        <Link to="/users"><li className="menu-item partners" onClick={() => setShowMobileMenu(!showMobileMenu)}>Lista użytkowników</li></Link>
                        <Link to="/events/my-calendar"><li className="menu-item my-calendar" onClick={() => setShowMobileMenu(!showMobileMenu)}>Mój kalendarz</li></Link>
                        <Link to="/events/reports"><li className="menu-item requests" onClick={() => setShowMobileMenu(!showMobileMenu)}>Zgłoszenia</li></Link>
                        <Link to="/myprofile"><li className="menu-item settings" onClick={() => setShowMobileMenu(!showMobileMenu)}>Ustawienia</li></Link>
                        <li className="menu-item logout" onClick={() => handleLogout()}>Wyloguj</li>
                    </ul>
                </div>
                <div className="bottom-bar-menu d-flex justify-content-around bg-white">
                    <Link to="/events/public">
                        <button type="button" className="btn btn-white">
                            <img src={KalendarzPubliczny} />
                            <span className="sr-only">Kalendarz publiczny</span>
                        </button>
                    </Link>
                    <button type="button" id="mobileMenuOpen" className="btn btn-white" onClick={() => setShowMobileMenu(!showMobileMenu)}>
                        <span className="sr-only">MENU</span>
                    </button>
                    <Link to="/events/my-calendar">
                        <button type="button" className="btn btn-white">
                            <img src={MojKalendarz} />
                            <span className="sr-only">Mój kalendarz</span>
                        </button>
                    </Link>
                </div>
            </nav>}
        </React.Fragment>
    )
}

export default LeftMenuComponent;