
import React from "react";

interface TextInputComponentProps {
    id: string;
    label?: string;
    type: string;
    error?: string | undefined;
    register: any;
}

const TextInputComponent: React.FC<TextInputComponentProps> = ({ id, label, type, error, register }) => {
    return (
        <React.Fragment>
            {label && <label className="label">{label}</label>}
            <input {...register(id)} type={type} className="form-control" />
            {
                error &&
                <p className="form-error" style={{color: '#981e32'}}>{error}</p>
            }
        </React.Fragment>
    )
}

export default TextInputComponent;