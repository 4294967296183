import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppSelector } from "../../app/hooks";
import store from "../../app/store";
import { handleApiErrors } from "../../app/utils";
import SwitchComponent from "../../components/Switch.component";
import TextArea from "../../components/Textarea.component";
import TextInputComponent from "../../components/TextInput.component";
import UserAutocompleteComponent from "../../components/UserAutocomplete.component";
import UserAvatarComponent from "../../components/UserAvatar.component";
import { setIsLoading } from "../../features/app/appSlice";
import { useCreateEventMutation, useLazyGetEventQuery, useUpdateEventMutation } from "../../services/events";
import schema from "./eventForm.schema";

interface EventFormValues {
    _id: string,
    description: string;
    private: boolean;
    date: string;
    dateFrom?: string;
    dateTo?: string;
    timeFrom: string;
    timeTo: string;
    location: string;
    name: string;
    participants: { user: any, status: string, rejectReason?: string }[];
}

const EventFormComponent: React.FC = () => {
    const history = useHistory();
    const { id, listType } = useParams<{ id: string, listType: string }>();
    const loggedUser = useAppSelector((state) => state.auth.user);
    const [getEvent, { data, isLoading: isEventLoading }] = useLazyGetEventQuery();
    const [createEvent, { isLoading: isCreateLoading, isSuccess: isCreateSuccess, data: eventCreationResult }] = useCreateEventMutation();
    const [updateEvent, { isLoading: isUpdateLoading, isSuccess: isUpdateSuccess, data: eventUpdateResult }] = useUpdateEventMutation();
    const [initialized, setInitialized] = useState(false);

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        setError,
        formState: { errors },
    } = useForm<EventFormValues>({
        resolver: yupResolver(schema),
        defaultValues: {
            date: moment().format("YYYY-MM-DD"),
            timeFrom: moment().add(1, "hour").startOf("hour").format("HH:mm"),
            timeTo: moment().add(2, "hour").startOf("hour").format("HH:mm")
        }
    });

    useEffect(() => {
        if (id) {
            getEvent(id);
        }
    }, [id]);

    useEffect(() => {
        if (eventCreationResult || eventUpdateResult) {
            toast.success("Zapisano");
            let redirectId = id || _.get(eventCreationResult, "document._id", "");
            history.push(`/events/${listType}/details/${redirectId}`);
            //nie wiem czy to jest po bożemu ale nie mam lepszego rozwiązania
            document.getElementById("event-list")?.dispatchEvent(new Event('reload'));
        }
    }, [isCreateSuccess, isUpdateSuccess]);

    useEffect(() => {
        store.dispatch(setIsLoading(isEventLoading || isCreateLoading || isUpdateLoading));
    }, [isEventLoading, isCreateLoading, isUpdateLoading]);

    useEffect(() => {
        if (data && data.document && !initialized) {
            const document = data.document;
            setValue("name", document.name);
            setValue("date", moment(document.dateFrom).format("YYYY-MM-DD"));
            setValue("timeFrom", moment(document.dateFrom).format("HH:mm"));
            setValue("timeTo", moment(document.dateTo).format("HH:mm"));
            setValue("location", document.location || "");
            setValue("description", document.description || "");
            setValue("private", !!document.private);
            setInitialized(true);
        }
    }, [data]);

    const handleSubmitEventForm = async (formValues: EventFormValues) => {
        try {
            formValues.dateFrom = moment(`${formValues.date} ${formValues.timeFrom}`, "YYYY-MM-DD HH:mm").format("X");
            formValues.dateTo = moment(`${formValues.date} ${formValues.timeTo}`, "YYYY-MM-DD HH:mm").format("X");
            if (id) {
                formValues._id = id;
                await updateEvent(formValues as any).unwrap();
            } else {
                await createEvent(formValues as any).unwrap();
            }
        } catch (err) {
            toast.error("Wystąpił błąd");
            handleApiErrors(setError, err);
        }
    }

    return (<React.Fragment>
        <div className="row content-heading px-2 py-0">
            <div className="col-sm-11 d-flex align-items-end justify-content-center justify-content-md-start">
                <h1 className="page-title py-4 px-0">{id ? "Edytuj wydarzenie" : "Nowe wydarzenie"}</h1>
            </div>
        </div>
        <form className="g-3 needs-validation col-12 p-0 d-flex flex-wrap" onSubmit={handleSubmit(handleSubmitEventForm)}>
            <div className="row content-row px-2">
                <div className="col-sm-12 p-0 pr-md-2 pb-4 col-md-8 d-flex flex-wrap align-content-start">
                    <div className="field d-flex flex-wrap">

                        <div className="col-md-12 p-0">
                            <div className="col-md-12">
                                <TextInputComponent id="name" type="text" error={errors.name?.message} label="Nazwa wydarzenia" register={register} />
                            </div>
                            <div className="col-md-6">
                                <TextInputComponent id="date" type="date" label="Data" register={register} />
                            </div>
                        </div>
                        <div className="col-md-12 p-0 d-flex">
                            <div className="col-md-6 ">
                                <TextInputComponent id="timeFrom" type="time" error={errors.dateFrom?.message} label="Czas od:" register={register} />
                            </div>
                            <div className="col-md-6">
                                <TextInputComponent id="timeTo" type="time" error={errors.dateTo?.message} label="Czas do:" register={register} />
                            </div>
                        </div>

                        <div className="col-md-12">
                            <TextInputComponent id="location" type="text" error={errors.location?.message} label="Lokalizacja" register={register} />
                        </div>
                        <div className="col-md-12">
                            <TextArea id="description" type="text" error={errors.description?.message} label="Opis" register={register} rows={5} />
                            <SwitchComponent
                                id="private"
                                customClass="custom-control custom-switch my-3"
                                label="Wydarzenie niepubliczne"
                                error={errors.private?.message}
                                register={register}
                            />
                        </div>
                    </div>
                </div>
                <div className="users-panel col-sm-12 col-md-4 px-md-3 d-flex flex-wrap align-content-start">

                    <div className="field">
                        <label className="label">Organizatorzy</label>
                        <div className="text description">
                            <ul className="users-list hosts">
                                <li className="d-flex align-items-center justify-content-between">
                                    <div className="d-flex align-items-center">
                                        <UserAvatarComponent user={{ avatar: loggedUser?.avatar, name: loggedUser?.name || "" }} />Ja
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {!id && <div className="field">
                        <div className="text description">
                            <UserAutocompleteComponent error={_.get(errors, "participants.message")} multi={true} label="Dodaj uczestników" id="participants" onOptionSelect={(ids: string[]) => setValue("participants", ids as any)} initialValues={[]} simpleValue={true} />
                        </div>
                    </div>}
                </div>

            </div>

            <div className="row btn-panel col-sm-12 mt-4 mx-0 ml-md-2 px-0">
                <button className="btn btn-primary px-5" type="submit">{id ? "Edytuj" : "Utwórz"}</button>
            </div>
        </form>
    </React.Fragment>);
}

export default EventFormComponent;