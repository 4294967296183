import { ListParams, baseQuery, parseListParams } from "../app/api";
import { createApi } from "@reduxjs/toolkit/query/react";

export interface User {
    _id?: string;
    type: boolean | string;
    active: boolean;
    lawyers: any[];
    phone: string;
    email: string;
    name: string;
    avatar?: string;
}

export interface NewUser {
    name: string,
    companyName: string,
    position: string,
    email: string,
    type: string | boolean,
    active: boolean,
    lawyers: any,
    phone: string,
    description: string,
    password: string;
    avatar?: string;
}

export interface UserResponse {
    success: boolean;
    user: User;
    token: string;
}

export interface LoginRequest {
    email: string;
    password: string;
}

export interface UpdateUserResponse {
    document: User,
    success: boolean
}

export const usersApi = createApi({
    reducerPath: "usersApi",
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getUsers: builder.query<any, ListParams>({
            query: (params) => ({
                url: parseListParams("user", params),
            }),
        }),
        getUser: builder.query<any, string>({
            query: (id) => ({
                url: `user/${id}`,
            }),
        }),
        updateUser: builder.mutation<UpdateUserResponse, User>({
            query: (data) => {
                const { _id, ...form } = data;
                return {
                    url: `user/${_id}`,
                    method: "PUT",
                    body: form,
                }
            },
        }),
        createUser: builder.mutation<UpdateUserResponse, NewUser>({
            query: (data) => {
                return {
                    url: `user`,
                    method: "POST",
                    body: data,
                }
            },
        }),
        deleteUser: builder.mutation<any, string>({
            query: (_id) => {
                return {
                    url: `user/${_id}`,
                    method: "PUT",
                    body: { active: false },
                }
            },
        }),
    }),
});

export const { useGetUsersQuery, useGetUserQuery, useUpdateUserMutation, useDeleteUserMutation, useCreateUserMutation } = usersApi;
