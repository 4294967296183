import React from "react";
import { useAppSelector } from "../app/hooks";

const LoaderComponent: React.FC = () => {
    let isLoading = useAppSelector((state) => state.app.isLoading);
    if (!isLoading) return null
    return (<div className="loader-wrap">
        <div className="loading">
            <div className="bounceball"></div>
            <div className="loader-text">Wczytuję</div>
        </div>
    </div>)
}

export default LoaderComponent;