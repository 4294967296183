export const handleEmailLawyer = (email: string) => {
    window.open(`mailto:${email}`);
}
export const handlePhoneLawyer = (phone: string) => {
    window.open(`tel:${phone}`)
}

export const handleApiErrors = (setError: any, err: any) => {
    if (err.data && err.data.errors) {
        for (const [key, value] of Object.entries(err.data.errors)) {
            setError((key as any), { message: value as string })
        }
    }
}

export const handleMenusCollapse = (leftOpen: boolean, rightOpen: boolean) => {
    let sidebarCollapse = document.getElementById("sidebarCollapse");
    let sidebar = document.getElementById("sidebar");
    let content = document.getElementById("content");

    if (sidebarCollapse && sidebar && content) {
        if (leftOpen) {
            sidebarCollapse.classList.remove('rotate180');
            sidebar.classList.remove('active');
        } else {
            sidebarCollapse.classList.add('rotate180');
            sidebar.classList.add('active');
        }
        if (rightOpen) {
            content.classList.remove('active');
        } else {
            content.classList.add('active');
        }
    }
}