
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useHistory } from "react-router";

import { handleApiErrors } from "../../app/utils";
import { useUpdateUserMutation, useDeleteUserMutation } from "../../services/users";
import { useAppSelector } from "../../app/hooks";
import TextInputComponent from "../../components/TextInput.component";
import SimpleButtonComponent from '../../components/SimpleButton.component';
import UserAutocomplete from '../../components/UserAutocomplete.component';
import ConfirmModalComponent from '../../components/ConfirmModal.component';
import Switch from '../../components/Switch.component';
import { useAppDispatch } from "../../app/hooks";
import { setIsLoading } from "../../features/app/appSlice";
import UserAvatarComponent from '../../components/UserAvatar.component';
import RemoveItemIcon from '../../assets/images/remove-item.svg'
import ImageCropper from '../../components/ImageCropper.component';

type MyProfileFormValues = {
    _id: string,
    name: string,
    companyName: string,
    position: string,
    email: string,
    password: string,
    password2: string,
    type: string,
    active: boolean,
    lawyers: any,
    phone: string,
    description: string,
    avatar: any;
}

const schema = yup.object({
    name: yup.string().required('Pole jest wymagane'),
    companyName: yup.string(),
    position: yup.string(),
    email: yup.string().email("Niepoprawny format e-mail").required('Pole jest wymagane'),
    type: yup.string(),
    active: yup.bool(),
    phone: yup.string(),
    description: yup.string(),
    password: yup.string(),
    password2: yup.string()
        .oneOf([yup.ref('password'), null], 'Hasła muszą być identyczne')
})

const MyProfileComponent: React.FC = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.auth.user);
    const [updateUser, { isLoading: updateLoading }] = useUpdateUserMutation();
    const [deleteUser] = useDeleteUserMutation();
    const {
        register,
        handleSubmit,
        setValue,
        setError,
        watch,
        formState: { errors },
    } = useForm<MyProfileFormValues>({
        defaultValues: user ? user : {},
        resolver: yupResolver(schema)
    });

    dispatch(setIsLoading(updateLoading))
    const [modalOpen, setModalOpen] = useState(false);

    const handleSubmitMyProfileForm = async (data: MyProfileFormValues) => {
        try {
            const response = await updateUser(data).unwrap();
            if (response?.success) {
                toast.success('Zaktualizowano użytkownika')
            } else {
                toast.error('Wystąpił błąd');
            }
        } catch (err) {
            toast.error('Wystąpił błąd');
            handleApiErrors(setError, err);
        }
    }

    const handleOpenUserDeleteModal = () => {
        setModalOpen(true);
    }

    const handleCloseUserDeleteModal = () => {
        setModalOpen(false);
    }

    const handleDeleteAccount = async () => {
        if (user && user._id) {
            deleteUser(user._id).unwrap().then(res => {
                if (res?.success) {
                    toast.success('Usunięto użytkownika');
                    localStorage.removeItem('token');
                    history.replace('/login');
                }
            }).catch(err => {
                handleApiErrors(setError, err);
            })
        }
        setModalOpen(false);
    }

   

    const handleSelectAvatar = (image: string) => {
        setValue('avatar', image)
    }

    const handleRemoveAvatar = () => {
        setValue('avatar', null)
    }

    return (
        <React.Fragment>
            {modalOpen && <ConfirmModalComponent
                text="Czy potwierdasz usuwanie konta?"
                headerText="Usuwanie konta"
                onCancel={handleCloseUserDeleteModal}
                onConfirm={handleDeleteAccount}
            />}

            <div className="row content-heading px-2 py-0">
                <div className="col-sm-11 d-flex align-items-end justify-content-center justify-content-md-start">
                    <h1 className="page-title py-4 px-0">Mój profil</h1>
                </div>
            </div>
            <form
                onSubmit={handleSubmit(handleSubmitMyProfileForm)}
                className="g-3 needs-validation col-12 p-0 d-flex flex-wrap" >
                <div className="row content-row px-2">
                    <div className="col-sm-12 p-0 pr-md-2 pb-4 d-flex flex-wrap align-content-start ">
                        <div className="field d-flex flex-wrap">

                            <div className="col-md-6">
                                <TextInputComponent
                                    label="Imię i nazwisko"
                                    id="name"
                                    type="text"
                                    error={errors.name?.message}
                                    register={register}
                                />
                            </div>
                            <div className="col-md-6 d-flex flex-wrap">
                                <label className="label col-sm-12 p-0">Zdjęcie profilowe</label>
                                <div className="pb-2 d-flex ">
                                    <UserAvatarComponent
                                        user={{
                                            name: watch('name'),
                                            avatar: watch('avatar')
                                        }}
                                    />
                                    {watch('avatar') && <img className="remove-item mx-3" src={RemoveItemIcon} onClick={handleRemoveAvatar} />}
                                </div>
                                <ImageCropper
                                    handleSelectAvatar={handleSelectAvatar}
                                />
                            </div>
                            <div className="col-md-12 p-0 d-flex">
                                <div className="col-md-6">
                                    <TextInputComponent
                                        label="Nazwa firmy"
                                        id="companyName"
                                        type="text"
                                        error={errors.companyName?.message}
                                        register={register}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <TextInputComponent
                                        label="Stanowisko"
                                        id="position"
                                        type="text"
                                        error={errors.position?.message}
                                        register={register}
                                    />
                                </div>
                            </div>
                            <div className="col-md-12 p-0 d-flex flex-wrap">
                                <div className="col-md-12">
                                    <Switch
                                        id="type"
                                        label="Stanowisko asystenckie"
                                        error={errors.type?.message}
                                        register={register}
                                    />
                                </div>
                                <div className="col-md-6 ">
                                    <TextInputComponent
                                        label="Adres e-mail"
                                        id="email"
                                        type="text"
                                        error={errors.email?.message}
                                        register={register}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <TextInputComponent
                                        label="Numer telefonu"
                                        id="phone"
                                        type="text"
                                        error={errors.phone?.message}
                                        register={register}
                                    />
                                </div>

                                <div className="col-sm-12 p-0 d-flex flex-wrap">
                                    <div className="field">
                                        <label className="label w-100 pl-3">Zmień hasło</label>
                                    </div>
                                    <div className="col-6">
                                        <TextInputComponent
                                            id="password"
                                            type="text"
                                            error={errors.password?.message}
                                            register={register}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <TextInputComponent
                                            id="password2"
                                            type="text"
                                            error={errors.password2?.message}
                                            register={register}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <TextInputComponent
                                        label="Opis"
                                        id="description"
                                        type="text"
                                        error={errors.description?.message}
                                        register={register}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <UserAutocomplete
                                        multi={true}
                                        label="Przypisz asystenta"
                                        id="invite"
                                        onOptionSelect={(option) => setValue('lawyers', option)}
                                        initialValues={user && user.lawyers ? user.lawyers : []}
                                    />

                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="col-12 mt-4">
                    <SimpleButtonComponent
                        title="Zapisz"
                        type="primary"
                        onClick={handleSubmit(handleSubmitMyProfileForm)}
                    />
                    <SimpleButtonComponent
                        title="Usuń konto"
                        type="danger"
                        onClick={handleOpenUserDeleteModal}
                    />
                </div>
            </form>
        </React.Fragment>

    )
}

export default MyProfileComponent;