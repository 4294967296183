import { IonRouterOutlet } from "@ionic/react";
import _ from "lodash";
import { useEffect } from "react";
import { Redirect, useHistory } from "react-router";
import { Route, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import DentonsLogoComponent from "./components/DentonsLogo.component";
import LeftMenuComponent from "./components/LeftMenu.component";
import LoaderComponent from "./components/Loader.component";
import RightMenuCollapseButtonComponent from "./components/RightMenuCollapseButton.component";
import { setCredentials } from "./features/auth/authSlice";
import EventDetailsComponent from "./pages/events/EventDetails.component";
import EventFormComponent from "./pages/events/EventForm.component";
import EventList from "./pages/events/EventList.component";
import LoginForm from './pages/login/LoginForm.component';
import MyProfileComponent from "./pages/settings/MyProfile.component";
import SettingsComponent from "./pages/settings/Settings.component";
import UserDetails from "./pages/users/UserDetails.component";
import UserFormComponent from "./pages/users/UserForm.component";
import UserList from "./pages/users/UserList.component";
import { useIsAuthQuery } from "./services/auth";
import ResetPasswordForm from './pages/login/ResetPassword.component';
import ConfirmPasswordForm from './pages/login/ConfirmPassword.component';

const App: React.FC = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const { data: isAuth, isLoading: isAuthLoading } = useIsAuthQuery();
    const loggedUser = useAppSelector((state) => state.auth.user);

    useEffect(() => {
        if (_.isFunction($)) {
            let elements: any = $('[data-toggle="tooltip"]');
            if (_.isFunction(elements.tooltip)) {
                elements.tooltip();
            }
        }
    }, [location.pathname]);

    useEffect(() => {
        if (isAuth && isAuth.success) {
            dispatch(setCredentials(isAuth));
            history.push("/events/public");
        }
    }, [isAuth]);

    return (
        <IonRouterOutlet>
            <DentonsLogoComponent />

            <div className="wrapper d-flex align-items-stretch">
                <Route exact path="/login">
                    <LoginForm />
                </Route>
                <Route exact path="/resetPassword">
                    <ResetPasswordForm />
                </Route>
                <Route exact path="/resetPassword/:token">
                    <ConfirmPasswordForm />
                </Route>

                {!loggedUser && !isAuthLoading && (!location.pathname.includes('resetPassword')) && <Redirect to="/login" />}

                {/* Main App */}
                {loggedUser && <LeftMenuComponent />}

                {/* Middle container */}
                {loggedUser && <div id="sidebar" className="hide">
                    {/* Middle routing */}
                    <Route path={["/users", "/userform"]}>
                        <UserList />
                    </Route>
                    <Route path={["/settings", "/myprofile"]}>
                        <SettingsComponent />
                    </Route>

                    <Route path={[
                        "/events/:listType(public|my-calendar|reports)",
                        "/events/:listType(public|my-calendar|reports)/form/:id?",
                        "/events/:listType(public|my-calendar|reports)/details/:id"
                    ]}>
                        <EventList />
                    </Route>
                </div>}
                <LoaderComponent />
                {/* Right container */}
                {loggedUser && <div id="content" className={`px-3 py-0 ${isNaN(parseInt(location.pathname.substring(location.pathname.lastIndexOf('/') + 1))) ? "active" : ''} `}>
                    <RightMenuCollapseButtonComponent />

                    {/* Right routing */}
                    <Route exact path="/users/:id">
                        <UserDetails />
                    </Route>
                    <Route path="/myprofile">
                        <MyProfileComponent />
                    </Route>
                    <Route path="/userform/:id?">
                        <UserFormComponent />
                    </Route>
                    <Route path={[
                        "/events/:listType(public|my-calendar|reports)/form/:id?"
                    ]}>
                        <EventFormComponent />
                    </Route>
                    <Route path={["/events/:listType(public|my-calendar|reports)/details/:id"]}>
                        <EventDetailsComponent />
                    </Route>
                </div>}
            </div>
        </IonRouterOutlet >
    );
};

export default App;
