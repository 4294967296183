import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useParams } from "react-router";
import { useHistory } from 'react-router-dom';
import { useEffect } from "react";
import { useConfirmPasswordMutation } from "../../services/auth";
import LogoDentons from "../../assets/images/logo-dentons.svg";
import TextInputComponent from "../../components/TextInput.component";

type ConfirmPasswordFormValues = {
    password: string;
    password2: string;
};

interface ConfirmPasswordParams {
    token: string;
}

const schema = yup.object({
    password: yup.string().required('Pole jest wymagane'),
    password2: yup.string()
        .oneOf([yup.ref('password'), null], 'Hasła muszą być identyczne')
})

const ConfirmPasswordForm: React.FC = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<ConfirmPasswordFormValues>({
        resolver: yupResolver(schema),
    });
    const history = useHistory();
    const params: ConfirmPasswordParams = useParams();
    const [confirmPassword] = useConfirmPasswordMutation();

    const handleSubmitLoginForm = async (data: ConfirmPasswordFormValues) => {
        try {
            const response = await confirmPassword({
                token: params.token,
                password: data.password,
            }).unwrap();

            if (response.success) {
                toast.success("Hasło zostało zmienione");
                history.replace('/login');
            }
        } catch (err) {
            toast.error("Wystąpił błąd");
        }
    };

    useEffect(() => {
        document.getElementById("content")?.classList.remove('active');
        document.getElementById("mobile-nav")?.classList.add('active');
    }, [])

    return (
        <div id="login" className="col-sm-12 px-3 py-0 ">
            <div className="dentons-logo-mobile">
                <img src={LogoDentons} />
            </div>

            <div className="row px-2 py-0 d-flex justify-content-between">
                <div className="col-md-6 col-sm-12 d-flex align-items-center mb-md-5 pb-md-5 ">
                    <h1 className="welcome-title px-4 text-white dentons-semi mb-md-5 pb-md-5 pt-0">
                        <span className="dentons-bold">Twoje spotkania</span>
                        <br />w jednym miejscu
                    </h1>
                </div>
                <div className="col-md-4 col-sm-12 d-flex align-items-start align-items-md-end justify-content-center">
                    <form
                        onSubmit={handleSubmit(handleSubmitLoginForm)}
                        className="pb-5 col-12 p-0 d-flex flex-wrap"
                    >
                        <div className="col-sm-12 field">
                            <div className="col-md-12">
                                <TextInputComponent
                                    label="Hasło"
                                    id="password"
                                    type="text"
                                    error={errors.password?.message}
                                    register={register}
                                />
                            </div>
                            <div className="col-md-12">
                                <TextInputComponent
                                    label="Powtórz hasło"
                                    id="password2"
                                    type="text"
                                    error={errors.password2?.message}
                                    register={register}
                                />
                            </div>
                        </div>

                        <div className="col-12 mt-4 d-flex justify-content-end">
                            <button className="btn btn-primary px-5" type="submit">
                                Potwierdź hasło
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ConfirmPasswordForm;
