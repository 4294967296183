import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import counterReducer from "../features/demo/counterSlice";
import authReducer from "../features/auth/authSlice";
import usersReducer from "../features/users/usersSlice";
import appReducer from "../features/app/appSlice";
import { authApi } from "../services/auth";
import { usersApi } from "../services/users";
import { eventsApi } from "../services/events";

const store = configureStore({
  reducer: {
    app: appReducer,
    counter: counterReducer, //delete before deploy
    [authApi.reducerPath]: authApi.reducer,
    auth: authReducer,
    [usersApi.reducerPath]: usersApi.reducer,
    users: usersReducer,
    [eventsApi.reducerPath]: eventsApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(authApi.middleware)
      .concat(usersApi.middleware),
});

setupListeners(store.dispatch);
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
