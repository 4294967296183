import React, { useState } from "react";
import { useHistory } from "react-router";

import AddIcon from '../../assets/images/add.svg'
import { handleEmailLawyer, handlePhoneLawyer } from '../../app/utils';
import { User } from '../../services/users';
import { useGetUsersQuery } from "../../services/users";
import { useAppSelector } from "../../app/hooks";

import MiddleMenuHeaderComponent from "../../components/MiddleMenuHeader.component";
import UserListRowComponent from "./UserListRow.component";

const UserListComponent: React.FC = () => {
    const history = useHistory();
    const user = useAppSelector((state) => state.auth.user);
    const [query, setQuery] = useState('');
    const params = { select: "name,avatar,phone,email" };
    const { data } = useGetUsersQuery(Object.assign({}, params, { name: query }));



    const renderUserList = () => {
        if (data && data.documents && data.documents.length) {
            return data.documents.map((lawyer: User) => {
                return <UserListRowComponent
                    key={lawyer._id}
                    name={lawyer?.name}
                    avatar={lawyer?.avatar}
                    firstButtonImage={lawyer.phone ? 'phone' : null}
                    firstButtonAction={() => handlePhoneLawyer(lawyer.phone)}
                    secondButtonImage={lawyer.email ? 'email' : null}
                    secondButtonAction={() => handleEmailLawyer(lawyer.email)}
                    onClick={() => handleGotoDetails(lawyer._id as string)}
                />
            })
        }
    }
    const handleGotoDetails = (id: string) => {
        document.getElementById("content")?.classList.remove('active');
        history.push(`/users/${id}`)
    }
    const handleGoToEditTeamMember = (e: any, id: string) => {
        document.getElementById("content")?.classList.remove('active')
        history.push(`/userform/${id}`)
        e.stopPropagation();
    }
    const handleGoToProfile = (e: any) => {
        document.getElementById("content")?.classList.remove('active');
        history.push(`/myprofile`)
        e.stopPropagation();
    }
    const handleGoToCalendar = (e: any, userId: string) => {
        history.push(`/events/my-calendar`, { goToCalendar: userId });
        e.stopPropagation();
    }
    const handleGoToUserForm = (e: any) => {
        document.getElementById("content")?.classList.remove('active');
        history.push('/userform')
        e.stopPropagation();
    }

    return (
        <React.Fragment>
            <MiddleMenuHeaderComponent
                headerText="Lista użytkowników"
                additional={<button type="button" className="btn" onClick={(e: any) => handleGoToUserForm(e)} data-toggle="tooltip"
                    data-placement="bottom" title="Dodaj użytkownika"><img src={AddIcon} /></button>}
            />

            <div className="partners-list d-flex flex-column">
                <div className="subheader-team px-4 pt-2 d-flex justify-content-between align-items-center">
                    <div className="field pt-0">
                        <label className="label mt-0">Mój zespół</label>
                        <div className="text description">
                            <ul className="users-list">
                                {/* Logged user */}
                                <UserListRowComponent
                                    name={user?.name as string}
                                    avatar={user?.avatar}
                                    firstButtonImage="edit"
                                    firstButtonAction={(e: any) => handleGoToProfile(e)}
                                    secondButtonImage="calendar"
                                    secondButtonAction={(e: any) => handleGoToCalendar(e, user!._id)}
                                    onClick={() => handleGotoDetails(user?._id as string)}
                                />
                                {/* Rest of the team */}
                                {
                                    user?.lawyers.map((lawyer) => {
                                        return (
                                            <UserListRowComponent
                                                key={lawyer._id}
                                                name={lawyer?.name}
                                                avatar={lawyer?.avatar}
                                                firstButtonImage="edit"
                                                firstButtonAction={(e: any) => handleGoToEditTeamMember(e, lawyer?._id as string)}
                                                secondButtonImage="calendar"
                                                secondButtonAction={(e: any) => handleGoToCalendar(e, lawyer?._id)}
                                                onClick={() => handleGotoDetails(lawyer._id as string)}
                                            />
                                        )
                                    })
                                }
                            </ul>

                        </div>
                    </div>
                </div>
                <div className="text description field">
                    <div className="searchbox mx-4  ">
                        <label className="label">Szukaj</label>
                        <input type="search" className="form-control" data-toggle="dropdown" aria-haspopup="true" onChange={(e) => setQuery(e.target.value)}
                            aria-expanded="false" />
                    </div>
                    <ul className="users-list p-4">
                        {renderUserList()}
                    </ul>
                </div>
            </div>
        </React.Fragment>
    )
};

export default UserListComponent;
