import * as yup from "yup";

export default yup
    .object({
        _id: yup.string(),
        description: yup.string(),
        private: yup.bool(),
        date: yup.string(),
        dateFrom: yup.string(),
        dateTo: yup.string(),
        timeFrom: yup.string(),
        timeTo: yup.string(),
        location: yup.string(),
        name: yup.string().required("Wartość jest wymagana"),
        participants: yup.array().of(yup.string()),
    })
    .required();