import _ from "lodash";
import moment from "moment";
import React from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { Event } from "../../services/events";

interface EventListCardComponentProps {
    event: Event
}

const EventListCardComponent: React.FC<EventListCardComponentProps> = ({ event }) => {

    const { listType } = useParams<{ listType: string }>();
    const loggedUser = useAppSelector((state) => state.auth.user);
    let confirmedParticipantsCount = 0;
    let waitingParticipantsCount = 0;
    let loggedUserStatus = null;

    _.each(event.participants, (participant) => {
        if (participant.status === "accepted") {
            confirmedParticipantsCount += 1;
        } else if (participant.status !== "rejected") {
            waitingParticipantsCount += 1;
        }
        if (loggedUser?._id === participant.user) {
            loggedUserStatus = participant.status;
        }
    });

    let isMyEvent = loggedUser?._id === _.get(event, "creator");

    return (<Link to={`/events/${listType}/details/${event._id}`}
        onClick={() => document.getElementById("content")?.classList.remove('active')}
        className={`event ${isMyEvent && "my-event"} ${loggedUserStatus === "rejected" ? "denied" : ""} col-10 bg-white d-flex flex-wrap justify-content-between align-items-center px-3 pt-3 pb-2 mb-3`}>
        <h2 className="event-name w-100">{event.name}</h2>
        <div className="event-footer w-100 d-flex justify-content-between align-items-center pt-2">
            <h3 className="event-time">{moment(event.dateFrom).format("HH:mm")} - {moment(event.dateTo).format("HH:mm")}</h3>
            <div className="event-status d-flex">
                {isMyEvent && <div className="users-requests" data-toggle="tooltip" data-placement="bottom"
                    title="Zgłoszonych uczestników">{waitingParticipantsCount}</div>}
                {loggedUserStatus === null && < div className="users-confirmed" data-toggle="tooltip" data-placement="bottom"
                    title="Potwierdzonych uczestników">{confirmedParticipantsCount || 1}</div>}
                {loggedUserStatus === "requestedEntry" && <div className="status-text request-sent">Oczekuje</div>}
                {loggedUserStatus === "invited" && <div className="status-text request-sent">Zaproszony</div>}
                {loggedUserStatus === "rejected" && <div className="status-text request-denied">Odrzucono</div>}
            </div>
        </div>
    </Link >)
}

export default EventListCardComponent;