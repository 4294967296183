import { baseQuery } from "../app/api";
import { createApi } from "@reduxjs/toolkit/query/react";

export interface User {
    _id: string;
    type: string;
    active: boolean;
    lawyers: {
        _id: string,
        name: string,
        avatar: string,
        phone: string,
        email: string
    }[];
    phone: string;
    email: string;
    name: string;
    avatar: string;
    companyName: string
}

export interface UserResponse {
    success: boolean;
    user: User;
    token: string;
}

export interface LoginRequest {
    email: string;
    password: string;
}
export interface ResetPasswordRequest {
    email: string;
}

interface ResetPasswordResponse {
    success: boolean;
}

interface ConfirmPasswordRequest {
    token: string,
    password: string;
}

export const authApi = createApi({
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        login: builder.mutation<UserResponse, LoginRequest>({
            query: (credentials) => ({
                url: "login",
                method: "POST",
                body: credentials,
            }),
        }),
        resetPassword: builder.mutation<ResetPasswordResponse, ResetPasswordRequest>({
            query: (credentials) => ({
                url: "sendPasswordResetMail",
                method: "POST",
                body: credentials,
            }),
        }),
        confirmPassword: builder.mutation<ResetPasswordResponse, ConfirmPasswordRequest>({
            query: (data) => {
                const { token, ...form } = data;
                return {
                    url: `resetPassword/${token}`,
                    method: "POST",
                    body: form,
                }
            },
        }),
        isAuth: builder.query<UserResponse, void>({
            query: () => ({
                url: "isAuth",
            })
        }),
    }),
});

export const { useLoginMutation, useIsAuthQuery, useResetPasswordMutation, useConfirmPasswordMutation } = authApi;
