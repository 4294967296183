import {
    ActionPerformed,
    PushNotificationSchema,
    PushNotifications,
    Token
} from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';
import { toast } from "react-toastify";
import { baseUrl } from './api';
import _ from "lodash";
import { LocalNotifications } from "@capacitor/local-notifications";

class Push {

    token: string;
    constructor() {
        this.token = "";
    }

    async init() {
        if (Capacitor.getPlatform() !== "web") {
            try {
                let result = await PushNotifications.requestPermissions()
                if (result.receive === 'granted') {
                    PushNotifications.register();
                } else {
                    toast.error("Brak uprawnień do wyświetlania powiadomień");
                };
            } catch (err) {
                toast.error("Brak uprawnień do wyświetlania powiadomień");
            }

            PushNotifications.addListener('registration', (token: Token) => {
                this.subscribeDevice(token.value);
                this.token = token.value;
                // toast.success("Zarejesrowano powiadomienia");
            });

            PushNotifications.addListener('registrationError', (error: any) => {
                // toast.error("Błąd rejestracji powiadomień");
            });

            PushNotifications.addListener(
                'pushNotificationReceived',
                async (notification: PushNotificationSchema) => {
                    // toast.success('Push received: ' + JSON.stringify(notification));
                    await LocalNotifications.schedule({
                        notifications: [
                            {
                                title: notification.title || "Nowe wydarzenia",
                                body: notification.body || "Sprawdź nowe wydarzenia",
                                id: _.random(1, 10000, false),
                                schedule: { at: new Date(Date.now() + 1000 * 5) }
                            },
                        ],
                    });
                },
            );

            PushNotifications.addListener(
                'pushNotificationActionPerformed',
                (notification: ActionPerformed) => {
                    // toast.success('Push action performed: ' + JSON.stringify(notification));
                },
            );
        }
    }

    async subscribeDevice(token: string) {
        try {
            let res = await fetch(`${baseUrl}subscribeDevice`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ token: token })
            });
            let json = res.json();
            // toast.info(`Subscribed ${token.substring(0, 5)}...`)
        } catch (err: any) {

        }
    }
}

export default new Push();
