import PhoneIcon from '../assets/images/phone-btn.svg';
import EmailIcon from '../assets/images/mail-btn.svg';

// Available images:
// phone, email

interface IconButtonProps {
    text: string;
    icon: string;
    onClick: any
}


const IconButtonComponent: React.FC<IconButtonProps> = ({ text, icon, onClick }) => {
    const getIcon = (image: string | null) => {
        switch (image) {
            case 'phone':
                return PhoneIcon;
            case 'email':
                return EmailIcon;
            default:
                return;
        }
    }
    return (
        <a className="profile-mail text-white" onClick={onClick} href="javascript:void(0);" ><img src={getIcon(icon)} /> {text}</a>
    )
}

export default IconButtonComponent;