
import React from "react";
import DentonsLogo from "../assets/images/logo-dentons.svg";

const DentonsLogoComponent: React.FC = () => {
    return (
        <div className="dentons-logo">
            <img src={DentonsLogo} />
        </div>

    )
}

export default DentonsLogoComponent;