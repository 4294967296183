
import React, { Fragment, useState, useEffect } from "react";
import { useGetUsersQuery, User } from "../services/users";
import { useAppSelector } from "../app/hooks";
import removeIcon from "../assets/images/remove-item.svg";
import UserAvatarComponent from "../components/UserAvatar.component";
import _ from "lodash";

interface UserAutocompleteComponentProps {
    id: string; //Id dla inputa autocomplete
    readonly?: boolean; //Czy input tylko readonly
    multi: boolean; //Czy mozna wybrac kilka wartosci czy jedną
    placeholder?: string; //Placeholder inputa
    label: string; //Label autocomplete
    error?: any; //Błędy
    hideChips?: boolean; //Czy ukryć chipsy z wybranymi opcjami
    onOptionSelect: (selected: any) => void; //Co zrobic po wybraniu opcji
    initialValues: any[]; //Wartosci początkowe
    simpleValue?: boolean, //Czy zwracac samo id
    customParams?: { [fieldname: string]: any }
}

const UserAutocompleteComponent: React.FC<UserAutocompleteComponentProps> = ({
    id,
    readonly = false,
    multi = true,
    placeholder = "Wyszukaj użytkownika",
    label,
    error,
    hideChips,
    onOptionSelect,
    simpleValue,
    initialValues,
    customParams = {}
}) => {

    const loggedUser = useAppSelector((state) => state.auth.user);
    let [values, setValues] = useState<User[]>([]);
    let [query, setQuery] = useState("");
    let [showOptions, setShowOptions] = useState(false);
    let [initialized, setInitialized] = useState(false);
    let [optionHovered, setOptionHovered] = useState(false);
    let [options, setOptions] = useState<User[]>([]);
    let params: any = {
        limit: 5,
        select: "name,avatar"
    };
    const { data, isLoading, refetch } = useGetUsersQuery(Object.assign({}, params, { name: query, ...customParams }) as any);

    useEffect(() => {
        if (data && data.documents instanceof Array) {
            setOptions(data.documents);
        }
    }, [data]);

    useEffect(() => {
        if (initialValues instanceof Array && !initialized) {
            setValues(initialValues);
            setInitialized(true);
        }
    }, [initialValues]);

    const renderChips = () => {
        if (hideChips) return null;
        return (<ul className="users-list guests mb-0">
            {values.map((value, index) => {
                return (<li
                    className="d-flex align-items-center justify-content-between"
                    key={`${id}chip${index}`}
                >
                    <div className="d-flex align-items-center">
                        <UserAvatarComponent user={value} />
                        {value._id === loggedUser?._id ? "Ja" : value.name}
                    </div>
                    <img src={removeIcon} alt="Usuń" className="remove-item" onClick={() => removeOption(index)} />
                </li>);
            })}
        </ul>);
    }

    const removeOption = (index: number) => {
        let newValues = values.slice();
        newValues.splice(index, 1);
        setValues(newValues);
        if (multi) {
            simpleValue ? onOptionSelect(newValues.map((opt => opt._id))) : onOptionSelect(newValues);
        } else {
            simpleValue ? onOptionSelect(_.get(newValues, "[0]._id", null)) : onOptionSelect(newValues);
        }
    }

    const renderOptions = () => {
        return <div style={{ cursor: "pointer", zIndex: 1000 }} className="list-group position-relative">
            <div className="position-absolute w-100">
                {options.map((option, index) => {
                    return <div className="list-group-item w-100 d-flex align-items-center" onClick={() => selectOption(option)} key={`option${index}`} onMouseOver={() => setOptionHovered(true)} onMouseOut={() => setOptionHovered(false)}>
                        <UserAvatarComponent user={option} />
                        {option._id === loggedUser?._id ? "Ja" : option.name}
                    </div>
                })}
            </div>
        </div>;
    }

    const selectOption = (option: User) => {
        if (multi) {
            let optionIndex = values.findIndex((value) => value._id === option._id);
            if (optionIndex === -1) {
                let newValues = [...values, option];
                setValues(newValues);
                simpleValue ? onOptionSelect(newValues.map((opt => opt._id))) : onOptionSelect(newValues);
            }
        } else {
            setValues([option]);
            simpleValue ? onOptionSelect(option._id) : onOptionSelect([option]);
        }
        setOptionHovered(false);
        setShowOptions(false);
    }

    const renderError = (errorToRender: any): any => {
        if (_.isString(errorToRender)) {
            return <p className="form-error" key={`error${_.uniqueId()}`} style={{ color: "rgb(152, 30, 50)" }}>{errorToRender}</p>;
        } else if (_.isArray(errorToRender)) {
            return errorToRender.map((err) => renderError(err));
        } else if (_.isObject(errorToRender)) {
            return _.values(errorToRender).map((err) => renderError(err));
        }
    }

    return (<div className="position-relative w-100">
        <label className="label">{label}</label>
        {renderChips()}
        <div className={`input-group ${error ? 'has-validation' : ''}`}>
            <input
                className="form-control"
                id={id}
                onChange={(e) => {
                    setQuery(e.target.value);
                    refetch();
                }}
                placeholder={placeholder}
                autoComplete="off"
                type="text"
                readOnly={!!readonly}
                onFocus={() => setShowOptions(true)}
                onBlur={() => optionHovered ? null : setShowOptions(false)}
            />
            {error && renderError(error)}
        </div>
        {showOptions && renderOptions()}
    </div>);
}

export default UserAutocompleteComponent;