
import React from "react";

interface MiddleMenuHeaderComponentProps {
    headerText: string,
    additional?: React.ReactElement
}
const MiddleMenuHeaderComponent: React.FC<MiddleMenuHeaderComponentProps> = ({ headerText, additional = null }) => {
    return (
        <React.Fragment>
            <div className="header p-4 d-flex justify-content-between align-items-center">
                <h1>{headerText}</h1>
                {additional}
            </div>
        </React.Fragment>
    )
}

export default MiddleMenuHeaderComponent;