


import React from "react";

interface ConfirmModalProps {
    headerText: string,
    text: string,
    onConfirm?: any,
    onCancel?: any,
}

const ConfirmModalComponent: React.FC<ConfirmModalProps> = ({ headerText, text, onConfirm, onCancel }) => {
    return (
        <div className="modal" role="dialog" >
            <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div className="modal-content rounded-0 border-0">
                    <div className="modal-header py-4 border-0">
                        <h4 className="modal-title dentons-semi text-center w-100 text-white" id="exampleModalCenteredLabel">
                            {headerText}</h4>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onCancel}>
                            <span aria-hidden="true" className="text-white">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body text-white text-center">
                        {text}
                    </div>
                    <div className="modal-footer border-0 d-flex justify-content-center">
                        <button type="button" className="btn px-5 py-2 btn-secondary" onClick={onCancel}>Anuluj</button>
                        <button type="button" className="btn px-5 py-2 btn-modal btn-primary" onClick={onConfirm}>Potwierdź</button>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ConfirmModalComponent;