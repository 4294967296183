import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export interface ListParams {
    [field: string]: string | number
}

export const baseUrl = "https://prawnicy-api.overcloud.usermd.net/";
// export const baseUrl = "http://10.1.1.112:3001/";
// export const baseUrl = "http://localhost:3001/";

export const baseQuery = fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers, { getState }) => {
        const token = getAuthToken();
        if (token) {
            headers.set("authorization", `JWT:${token}`);
        }
        return headers;
    },
})

export const getAuthToken = (): string | null => {
    return localStorage.getItem("token");
};

export const parseListParams = (baseUrl: string, params: ListParams): string => {
    let parsed: string[] = [];
    Object.keys(params).forEach((param: string) => {
        parsed.push(`${param}=${params[param]}`);
    });
    return parsed.length > 0 ? `${baseUrl}?${parsed.join("&")}` : baseUrl
}
