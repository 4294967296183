
import React from "react";
import SidebarClose from "../assets/images/sidebar-close.svg";

const RightMenuCollapseButtonComponent: React.FC = () => {

    const handleCollapse = () => {
        document.getElementById("content")?.classList.add('active');
    }

    return (
        <div className="custom-menu">
            <button type="button" id="contentCollapse" className="btn btn-primary" onClick={() => handleCollapse()}>
                <img src={SidebarClose} />
                <span className="sr-only">Zwiń panel</span>
            </button>
        </div>
    )
}

export default RightMenuCollapseButtonComponent;