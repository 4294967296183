
import React from "react";
import UserAvatarComponent from '../../components/UserAvatar.component';
import EditIcon from '../../assets/images/edit.svg';
import CalendarIcon from '../../assets/images/calendar-btn.svg';
import PhoneIcon from '../../assets/images/phone-btn.svg';
import EmailIcon from '../../assets/images/mail-btn.svg';

// Available images:
// edit, calendar, phone, email

//TODO sprawdzic co jest wymagane
interface UserListRowProps {
    avatar?: string;
    name: string;
    firstButtonImage: string | null;
    firstButtonAction: any;
    secondButtonImage?: string | null;
    secondButtonAction?: any;
    onClick?: any
}

const UserListRowComponent: React.FC<UserListRowProps> = ({ avatar, name, firstButtonAction, firstButtonImage, secondButtonAction, secondButtonImage, onClick }) => {
    const getIcon = (image: string | null) => {
        switch (image) {
            case 'calendar':
                return CalendarIcon;
            case 'edit':
                return EditIcon;
            case 'phone':
                return PhoneIcon;
            case 'email':
                return EmailIcon;
            default:
                return;
        }
    }
    return (
        <li className="d-flex align-items-center justify-content-between" onClick={onClick}>
            <div className="name d-flex align-items-center">

                {(avatar) ?
                    <img className="avatar image"
                        src={avatar} />
                    :
                    <UserAvatarComponent
                        user={{ avatar, name }}
                    />
                }
                {name}

            </div>
            <div className="contact-me d-flex">
                <div className="rounded-circle p-0" onClick={firstButtonAction}><img src={getIcon(firstButtonImage)} /></div>
                {secondButtonImage && secondButtonAction && <div className="rounded-circle p-0" onClick={secondButtonAction}><img src={getIcon(secondButtonImage)} /></div>}
            </div>
        </li>
    )
}

export default UserListRowComponent;