
import React from "react";

interface SimpleButtonInterface {
    title: string,
    type: string,
    onClick?: any
}

const SimpleButtonComponent: React.FC<SimpleButtonInterface> = ({ title, type, onClick }) => {
    let className: string;

    switch (type) {
        case "primary":
            className = "btn btn-primary"
            break;
        case "danger":
            className = "btn btn-outline-danger border-0"
            break;
        case "success":
            className = "btn btn-outline-success btn font-weight-bold px-md-4 px-sm-2"
            break;
        default:
            className = "btn btn-primary"
    }

    return (
        <button className={className} onClick={onClick}>{title}</button>
    )
}

export default SimpleButtonComponent;