import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { User } from "../../services/auth";
import type { RootState } from "../../app/store";

type AuthState = {
  user: User | null;
  token: string | null;
};

const saveAuthToken = (token: string | null): void => {
  if (token) {
    localStorage.setItem("token", token);
  } else {
    localStorage.removeItem("token");
  }
};

const intitialState: AuthState = {
  user: null,
  token: null,
};

const slice = createSlice({
  name: "auth",
  initialState: intitialState,
  reducers: {
    setCredentials: (
      state,
      { payload: { user, token } }: PayloadAction<AuthState>
    ) => {
      saveAuthToken(token);
      state.user = user;
      state.token = token;
    },
  },
});

export const { setCredentials } = slice.actions;

export const selectCurrentUser = (state: RootState) => state.auth.user;

export default slice.reducer;
