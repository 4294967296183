import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { IonApp } from "@ionic/react";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

// /* Core CSS required for Ionic components to work properly */
// import "@ionic/react/css/core.css";

// /* Basic CSS for apps built with Ionic */
// import "@ionic/react/css/normalize.css";
// import "@ionic/react/css/structure.css";
// import "@ionic/react/css/typography.css";

// /* Optional CSS utils that can be commented out */
// import "@ionic/react/css/padding.css";
// import "@ionic/react/css/float-elements.css";
// import "@ionic/react/css/text-alignment.css";
// import "@ionic/react/css/text-transformation.css";
// import "@ionic/react/css/flex-utils.css";
// import "@ionic/react/css/display.css";

// /* Theme variables */
// import "./theme/variables.css";

import "./assets/css/bootstrap.min.css";
import "./assets/css/style.css";
import "./assets/css/dentons.css";

import App from "./App";
import store from "./app/store";
import PushService from "./app/push";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment";
import plLocale from "./assets/moment/pl";
moment.locale("pl", plLocale as any);

const render = () => {

  PushService.init();

  return (ReactDOM.render(
    <Provider store={store}>
      <IonApp>
        <Router>
          <App />
          <ToastContainer
            position="bottom-right"
          />
        </Router>
      </IonApp>
    </Provider>,
    document.getElementById("root")));
};

render();

if (module.hot) {
  module.hot.accept();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
