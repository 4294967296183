import React, { useRef, useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Resizer from "react-image-file-resizer";

interface CropperProps {
    handleSelectAvatar: any
}

const ImageCropperComponent: React.FC<CropperProps> = ({ handleSelectAvatar }) => {
    const [cropperImage, setCropperImage] = useState('');
    const [showCropper, setShowCropper] = useState(false);
    const cropperRef = useRef<HTMLImageElement>(null);
    let cropper: any;
    const onCrop = () => {
        const imageElement: any = cropperRef?.current;
        cropper = imageElement?.cropper;
    };

    const resizeFile = (file: any) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                400,
                400,
                "PNG",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "base64"
            );
        });

    const handlePickAvatar = async (e: any) => {
        const file = await resizeFile(e.target.files[0]);
        if (file) {
            setCropperImage(file as string);
            setShowCropper(true);
        }
    }

    const sendAvatar = async () => {
        setShowCropper(false);
        handleSelectAvatar(cropper.getCroppedCanvas().toDataURL());
    }

    const renderCropper = () => {
        return (
            <div className="modal" role="dialog" >
                <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div className="modal-content rounded-0 border-0">
                        <div className="modal-header py-4 border-0">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setShowCropper(false)} >
                                <span aria-hidden="true" className="text-white">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body text-white text-center">
                            <Cropper
                                src={cropperImage}
                                style={{ height: 400 }}
                                // Cropper.js options
                                aspectRatio={1 / 1}
                                guides={false}
                                crop={onCrop}
                                ref={cropperRef}
                            />
                        </div>
                        <div className="modal-footer border-0 d-flex justify-content-center">
                            <button type="button" className="btn px-5 py-2 btn-secondary" onClick={() => setShowCropper(false)}>Anuluj</button>
                            <button type="button" className="btn px-5 py-2 btn-modal btn-primary" onClick={() => sendAvatar()} >Potwierdź</button>
                        </div>
                    </div>
                </div>
            </div >
        )
    }

    return (
        <div>
            {showCropper && renderCropper()}

            <input type="file"
                id="avatar"
                accept="image/png, image/jpeg"
                onChange={(e) => handlePickAvatar(e)}
            />
        </div>
    )
}

export default ImageCropperComponent;