import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";

type AppState = {
    isLoading: boolean
};

const intitialState: AppState = {
    isLoading: false
};

const slice = createSlice({
    name: "app",
    initialState: intitialState,
    reducers: {
        setIsLoading: {
            reducer: (state, action: PayloadAction<boolean>) => {
                state.isLoading = action.payload;
            },
            prepare: (isLoading: boolean) => {
                return {  payload: isLoading  };
            }
        }
    },
});

export const { setIsLoading } = slice.actions;
export const getIsLoading = (state: RootState) => state.app.isLoading;
export default slice.reducer;
