import React, { useEffect, useState } from "react";
import arrowLeftIcon from "../../assets/images/arrow-left.svg";
import arrowRightIcon from "../../assets/images/arrow-right.svg";
import moment from "moment";
import _ from "lodash";

interface EventListFilterComponentProps {
    onDateChange: (currentDate: Date) => void,
    currentDate: Date
}

const EventListFilterComponent: React.FC<EventListFilterComponentProps> = ({ onDateChange, currentDate }) => {

    const moveDayForward = () => {
        onDateChange(moment(currentDate).add(1, "day").startOf("day").toDate());
    }

    const moveDayBackward = () => {
        onDateChange(moment(currentDate).subtract(1, "day").startOf("day").toDate());;
    }

    const moveToToday = () => {
        if (moment(currentDate).format("DD.MM.YYYY") !== moment().format("DD.MM.YYYY")) {
            let newDate = moment().startOf("day").toDate();
            onDateChange(newDate);
        }
    }

    return (<div className="subheader p-4 d-flex justify-content-between align-items-center">
        <button type="button" className="btn btn-outline-success btn font-weight-bold px-md-4 px-sm-2" onClick={() => moveToToday()}>Dziś</button>
        <h5 className="text-center">{_.upperFirst(moment(currentDate).format("dddd, D MMMM"))}</h5>
        <div className="date-nav mx-md-4 mx-sm-1">
            <button type="button" className="btn" data-toggle="tooltip"
                data-placement="bottom" title="Poprzedni dzień" onClick={() => moveDayBackward()}><img src={arrowLeftIcon} /></button>
            <button type="button" className="btn" data-toggle="tooltip"
                data-placement="bottom" title="Następny dzień" onClick={() => moveDayForward()}><img src={arrowRightIcon} /></button>
        </div>
    </div>)
}

export default EventListFilterComponent;