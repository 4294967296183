
import React from "react";

interface SwitchComponentProps {
    id: string;
    label?: string;
    error: string | undefined;
    register: any;
    customClass?: string;
    disabled?: boolean
}

const SwitchComponent: React.FC<SwitchComponentProps> = ({ id, label, error, register, customClass, disabled }) => {
    return (
        <React.Fragment>
            <div className={customClass ? customClass : `custom-control custom-switch custom-switch-md mt-4`}>
                <input {...register(id)} type="checkbox" className="custom-control-input" id={id} disabled={disabled} />
                <label className="custom-control-label" htmlFor={id} >{label}</label>
                {
                    error &&
                    <p>{error}</p>
                }
            </div>
        </React.Fragment>
    )
}

export default SwitchComponent;