import { ListParams, baseQuery, parseListParams } from "../app/api";
import { createApi } from "@reduxjs/toolkit/query/react";

export interface Event {
    _id: string;
    creator: any
    createdAt: string;
    description: string;
    fullDay: boolean;
    private: boolean;
    dateFrom: string;
    dateTo: string;
    location: string;
    name: string;
    participants: { user: any, status: string, rejectReason?: string }[];
}

export interface EventsResponse {
    success: boolean;
    documents: Event[];
    total: number
}

export interface EventResponse {
    success: boolean;
    document: Event;
}

export interface EventRestResponse {
    success: boolean;
    document?: string;
}

export const eventsApi = createApi({
    reducerPath: "eventsApi",
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getEvents: builder.query<any, ListParams>({
            query: (params) => ({
                url: parseListParams("event", params)
            })
        }),
        getEvent: builder.query<any, string>({
            query: (id) => ({
                url: `event/${id}`
            })
        }),
        createEvent: builder.mutation<EventRestResponse, Event>({
            query: (data) => {
                return {
                    url: "event",
                    method: "POST",
                    body: data,
                }
            },
        }),
        updateEvent: builder.mutation<EventRestResponse, Event>({
            query: (data) => {
                return {
                    url: `event/${data._id}`,
                    method: "PUT",
                    body: data,
                }
            },
        }),
        deleteEvent: builder.mutation<EventRestResponse, string>({
            query: (_id) => {
                return {
                    url: `event/${_id}`,
                    method: "DELETE",
                    body: { active: false },
                }
            },
        }),
        requestEntry: builder.mutation<EventRestResponse, { event: string, users: string[] }>({
            query: (data) => {
                return {
                    url: "requestEntry",
                    method: "POST",
                    body: data,
                }
            },
        }),
        inviteUsersToEvent: builder.mutation<EventRestResponse, { event: string, users: string[] }>({
            query: (data) => {
                return {
                    url: "inviteUsersToEvent",
                    method: "POST",
                    body: data,
                }
            },
        }),
        replyToEntryRequest: builder.mutation<EventRestResponse, { event: string, user: string, status: string, rejectReason?: string }>({
            query: (data) => {
                return {
                    url: "replyToEntryRequest",
                    method: "POST",
                    body: data,
                }
            }
        }),
        replyToInvitation: builder.mutation<EventRestResponse, { event: string, status: string, rejectReason?: string }>({
            query: (data) => {
                return {
                    url: "replyToInvitation",
                    method: "POST",
                    body: data,
                }
            },
        })
    }),
});

export const {
    useGetEventsQuery,
    useGetEventQuery,
    useLazyGetEventQuery,
    useInviteUsersToEventMutation,
    useReplyToInvitationMutation,
    useRequestEntryMutation,
    useReplyToEntryRequestMutation,
    useCreateEventMutation,
    useUpdateEventMutation
} = eventsApi;
